<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('SubCompanyID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='SubCompanyID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Ruc')" class="sh">
                                    {{'identity_document' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Ruc')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Name')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                                <td>{{i + 1}}</td>
                                <td>{{row.NumberIdentification}}</td>
                                <td>{{row.Name}}</td>
                                <td class="align-text-top"> <div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.SubCompanyID}}" name="{{row.Name}}" (change)="toggleEditable($event,row.EmployeeId)" type="checkbox">
                                  </div></td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="goTo('/regemployeedoc/'+row.EmployeeId, {})" style="cursor:pointer;" class="ti-view-list-alt text-info m-r-10" title="{{'menu_title_man_documento' | translate}}"></i>
                                    <i (click)="Activar(row.EmployeeId, '3')" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
                <!--cambio-->
                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}} </h4>
                    </div>
                    <div class="modal-body">
                        <!-- main row -->
                        <div class="row">
                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <div><label>{{'picture' | translate}}</label> </div>
                                                 <div class="form-group row"> <!--enctype="multipart/form-data" class="custom-file col-sm-9">-->
                                                  <img id="blah" [src]="imageSrc || default" alt="your image" style="width: 30%;" />
                                                </div>
                                                <div class="form-group row"><input class="form-control" type="file" name="file" (change)="onFileChanged($event)" accept="image/*" multiple="true" id="file" required/></div>
                                            </div>
                                            <div class="col-md-6 ha-ibox"></div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company' | translate}}</label>
                                                <input type="text" class="form-control" name="company"  value="{{userinfo['name']}}" disabled="true"> <!--[(ngModel)]="u_obj['cmpName']"-->
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company_type' | translate}}</label>
                                                <input type="text" class="form-control" name="typecompany"  value="{{(userinfo['typeCompany']=== 1)? 'Contratista':'' || (userinfo['typeCompany'] === null)? '':''}}" readonly>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'identity_type' | translate}}</label>
                                                <ng-select [(ngModel)]="c_obj.TypeIdentificationID" name="TypeIdentificationID"  [multiple]="false">
                                                    <ng-option *ngFor="let p of type_document" [value]="p.ID">{{p.value}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'identity_document' | translate}}</label>
                                                <input type="text" maxlength="13" placeholder="Ingrese identificación" class="form-control" pattern="^[A-Z0-9<]+$" name="identification" [(ngModel)]="c_obj['NumberIdentification']" (blur)="searchEmployee()" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'fullname' | translate}}</label>
                                                <input type="text" placeholder="Nombre completo" class="form-control" name="name" [(ngModel)]="c_obj['Name']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox" >
                                                <label>{{'birth_date' | translate}}</label>
                                                <input type="date" class="form-control" name="ndate" [(ngModel)]="c_obj['Birth_date']">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'myemail' | translate}}</label>
                                                <input type="text" placeholder=""  class="form-control" name="emil" [(ngModel)]="c_obj['Email']" required>
                                            </div>
                                            <!-- <div class="col-md-6 ha-ibox">
                                                <label>{{'nationality' | translate}}</label>
                                                <input type="text" class="form-control" name="nationality" [(ngModel)]="c_obj['nationality']" required>
                                            </div> -->
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'nationality' | translate}}</label>
                                                <ng-select [(ngModel)]="c_obj.nationalityID" name="nationalityID"  [multiple]="false">
                                                    <ng-option *ngFor="let p of type_nacionality" [value]="p.ID">{{p.value}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'cellphone' | translate}}</label>
                                                <input type="text" class="form-control" name="phone" maxlength="10" (keypress)="onlyNumberKey($event)" [(ngModel)]="c_obj['telephone']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'phone2' | translate}}</label>
                                                <input type="text" class="form-control" name="phone2" maxlength="10" (keypress)="onlyNumberKey($event)" [(ngModel)]="c_obj['telephone2']">
                                            </div>

                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'direccion' | translate}}</label>
                                                <textarea  rows="3"   class="form-control" name="address" [(ngModel)]="c_obj['address']" required style="resize: none;"></textarea>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'jobtitle' | translate}}</label>
                                                <input type="text" class="form-control" name="typejob" [(ngModel)]="c_obj['position']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'add' | translate}} {{'globalparam_typejob' | translate}}</label>
                                                <ng-select [(ngModel)]="curr_parameter" name="type"  [multiple]="true" (change)="addJob()">
                                                    <ng-option *ngFor="let p of tipotrabajo" [value]="p['JobTypeID']">{{p.Name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                          <!-- end details -->

                        </div>
                        <!-- end row main -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'close' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <!--fin cambio-->
                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['Name']}} </h4>
                    </div>
                    <div class="modal-body">
                        <!-- main row -->
                        <div class="row">
                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <div><label>{{'picture' | translate}}</label> </div>
                                                 <div class="form-group row"  enctype="multipart/form-data" >
                                                  <img id="blah"  [src]=" imageSrc || u_obj['Picture']" alt="your image" style="width: 40%;" />
                                                </div>
                                                <div class="form-group row">
                                                    <input class="form-control" type="file" name="file" (change)="onFileChanged($event)" accept="image/*" multiple="true" id="file" required/>
                                                </div>
                                            </div>
                                            <div class="col-md-6 ha-ibox"></div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company' | translate}}</label>
                                                <input type="text" class="form-control" name="company"  value="{{userinfo['name']}}" disabled="true"> <!--[(ngModel)]="u_obj['cmpName']"-->
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company_type' | translate}}</label>
                                                <input type="text" class="form-control" name="typecompany"  value="{{(userinfo['typeCompany']=== 1)? 'Contratista':'' || (userinfo['typeCompany'] === null)? '':''}}" readonly>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{ 'identity_type' | translate }}</label>
                                                <input type="text" class="form-control" name="TypeIdentificationID" 
                                                       [value]="this.tipo_documento_edit" disabled="true">
                                            </div>
                                            
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'identity_document' | translate}}</label>
                                                <input type="text" maxlength="13"  (keypress)="onlyNumberKey($event)"  class="form-control" name="identification" [(ngModel)]="u_obj['NumberIdentification']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" >
                                                <label>{{'birth_date' | translate}}</label>
                                                <input type="date" class="form-control" name="ndate" [(ngModel)]="u_obj['Birth_date']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'fullname' | translate}}</label>
                                                <input class="form-control" name="name" [(ngModel)]="u_obj['Name']" required>
                                            </div>

                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'myemail' | translate}}</label>
                                                <input type="text" class="form-control" name="emil" [(ngModel)]="u_obj['Email']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'nationality' | translate}}</label>
                                                <input type="text" class="form-control" name="nationality" [(ngModel)]="u_obj['nationality']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'phone' | translate}}</label>
                                                <input type="text" (keypress)="onlyNumberKey($event)" class="form-control" name="phone" [(ngModel)]="u_obj['telephone']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'phone2' | translate}}</label>
                                                <input type="text" (keypress)="onlyNumberKey($event)" class="form-control" name="phone2" [(ngModel)]="u_obj['telephone2']" required>
                                            </div>
                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'direccion' | translate}}</label>
                                                <textarea  rows="3"  class="form-control" name="address" [(ngModel)]="u_obj['address']" required style="resize: none;"></textarea>
                                            </div>

                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'jobtitle' | translate}}</label>
                                                <input type="text" class="form-control" name="typejob" [(ngModel)]="u_obj['position']" required>
                                            </div>

                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'add' | translate}} {{'globalparam_typejob' | translate}}</label>
                                                <ng-select [(ngModel)]="curr_parameter" name="typeas"  [multiple]="true" (change)="addJobUpdate()">
                                                    <ng-option *ngFor="let p of tipotrabajo"
                                                    [value]="p['JobTypeID']">
                                                        {{p.Name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'close' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
