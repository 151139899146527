import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { LoginService } from '../../../services/authentication/login.service';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {



  @Output() toggleSidebar = new EventEmitter<void>();

  public user: any;
  public company: any;
  public photo: any;

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private storage: Storage,
    private srv: LoginService
  ) {
    // informacion del usuario
    storage.get('userinfo').then(x => {
      this.user = x.name;
      this.company = x.selectedCompanyName;
      // console.log(this.user)
      this.photo = x.photo;
    });
  }

  ngAfterViewInit() {}

  public logout() {
      this.srv.clearData().then(() => {
          this._router.navigate(['/authentication/login']);
      });
  }

}
