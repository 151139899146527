
<div class="container">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 p-container-worker-form">
            <form [formGroup]="form" class="formWorkerInfo">
                <div class="mt-2">
                    <img src="../../../assets/images/duragas_logo.svg" width="50%">
                </div>
                <div class="spinner" [hidden]="containData"></div>
                <div [hidden]="!containData">
                    <hr>
                    <div class="title">
                        <h3>{{'data_details_request' | translate}}</h3>
                    </div>
                    <div class="text-center mt-2 mb-2 ">
                        <img [src]="endOutWorker['ImageProfile']" [alt]="endOutWorker['EmployeeName']" class="avatar" title="{{endOutWorker['EmployeeName']}}">
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ti-stamp"></i> &nbsp; {{'company' | translate}}</span>
                        </div>
                        <input type="text" class="form-control" formControlName="company" name="CompanyName" placeholder="{{'company' | translate}}" [(ngModel)]="endOutWorker['CompanyName']">
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ti-user"></i> &nbsp; {{'worker' | translate}}</span>
                        </div>
                        <input type="text" class="form-control" formControlName="worker" placeholder="{{'worker' | translate}}"  [(ngModel)]="endOutWorker['EmployeeName']" >
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ti-user"></i> &nbsp; {{'worker' | translate}}</span>
                        </div>
                        <input type="text" class="form-control" formControlName="typeEmployeeName" placeholder="{{'employee_type' | translate}}"  [(ngModel)]="endOutWorker['typeEmployeeName']" >
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ti-calendar"></i> &nbsp; {{'init_work' | translate}}</span>
                        </div>
                        <input type="text" class="form-control" formControlName="fromDateWork" placeholder="{{'init_work' | translate}}" [(ngModel)]="endOutWorker['StartDate']">
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ti-calendar"></i> &nbsp; {{'end_work' | translate}}</span>
                        </div>
                        <input type="text" class="form-control" formControlName="toDateWork" placeholder="{{'end_work' | translate}}" [(ngModel)]="endOutWorker['EndDte']">
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ti-hummer"></i> &nbsp; {{'job_to_do' | translate}}</span>
                        </div>
                        <textarea type="text" class="form-control" formControlName="work" placeholder="{{'job_to_do' | translate}}" [(ngModel)]="endOutWorker['WorkDescription']">
                        </textarea>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-6">
                            <button [ladda]=isCreating class="btn" type="button" data-style="expand-left"  (click)="openToolsModal(tools)"><i class="fas fa-wrench"></i>&nbsp;{{'see_tools' | translate}}</button>
                        </div>
                        <div class="col-md-6">
                            <button [ladda]=isCreating class="btn" type="button" data-style="expand-right" (click)="openCarsModal(cars)"><i class="ti-car"></i>&nbsp;{{'see_vehicle' | translate}}</button>
                        </div>
                    </div> -->
                    <h2 *ngIf="qrValid" class="mt-4 text-success" > <strong>{{'msg_worker_in' | translate}}</strong></h2>
                    <h2 *ngIf="!qrValid" class="mt-4 text-danger" > <strong>{{'msg_worker_out' | translate}}</strong></h2>
                    <br>
                    <h3 *ngIf="listaRazones.length > 0" style="text-align: left;">{{'reasons' | translate}}</h3>
                    <ul *ngIf="listaRazones.length > 0">
                        <li *ngFor="let item of listaRazones" style="text-align: left;">
                            <span>{{item.name}} : <strong> <span [style.color]="item['color']">{{item.status}}</span></strong> </span>
                        </li>
                    </ul>
                    <button [ladda]=isCreating class="btn" type="button" [hidden]='(!qrValid)' (click)="confirmAdmission()">{{'confirm' | translate}}</button>
                </div>

            </form>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>
<!-- <ng-template #tools let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'see_tools' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white">
                    <tr>
                        <th class="text-nowrap">{{'code' | translate}}</th>
                        <th class="text-nowrap">{{'description' | translate}}</th>
                        <th class="text-nowrap">{{'brand_model' | translate}}</th>
                        <th class="text-nowrap">{{'quantity' | translate}}</th>
                        <th class="text-nowrap">{{'notice_in' | translate}}</th>
                        <th class="text-nowrap">{{'notice_out' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of listTools; index as i">
                        <td >{{i + 1}}</td>
                        <td >{{row.Description}}</td>
                        <td >{{row.ModelBrand}}</td>
                        <td >{{row.quantity}}</td>
                        <td >{{row.EntryNews}}</td>
                        <td >{{row.DeparturesNews}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
<ng-template #cars let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'see_vehicle' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white">
                    <tr>
                        <th class="text-nowrap">{{'code' | translate}}</th>
                        <th class="text-nowrap">{{'v_model' | translate}}</th>
                        <th class="text-nowrap">{{'v_registration' | translate}}</th>
                        <th class="text-nowrap">{{'v_color' | translate}}</th>
                        <th class="text-nowrap">{{'status' | translate}}</th>
                        <th class="text-nowrap">{{'action' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of listCars; index as i">
                        <td >{{i + 1}}</td>
                        <td >{{row.Model}}</td>
                        <td >{{row.CarRegistration}}</td>
                        <td >{{row.Color}}</td>
                        <td >{{row.estadoGeneral}}</td>
                        <td ><i style="cursor: pointer;" class="fa fa-info" title="{{'see_detail' | translate}}" (click)="seeDetailDocument(row._DocumentsVehicle)"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="havedata">
            <h4>{{'doc_vehicles' | translate}}</h4>
            <table class="table">
                <thead class="table-head text-white">
                    <tr>
                        <th class="text-nowrap">{{'code' | translate}}</th>
                        <th class="text-nowrap">{{'document' | translate}}</th>
                        <th class="text-nowrap">{{'description' | translate}}</th>
                        <th class="text-nowrap">{{'Obligatorio' | translate}}</th>
                        <th class="text-nowrap">{{'status' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of detalleDocumentos; index as i">
                        <td >{{i + 1}}</td>
                        <td >{{row.document}}</td>
                        <td >{{row.description}}</td>
                        <td >{{row.Obligatorio ? 'Obligatorio' : 'No obligatorio'}}</td>
                        <td >{{row.Status == "V" ? "Aprobado" : "Reprobado"}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="havedata == false">
            <h4>{{'dont_have_document' | translate}}</h4>
        </div>
    </div>
</ng-template> -->
