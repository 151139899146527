<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group" *ngIf="rows.length !== 0;">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <!--<button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>-->
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages"
                            (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' |
                            translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('EvaluationId')" class="sh">
                                    {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='EvaluationId')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('EvaluationName')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='EvaluationName')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('NumeroPregunta')" class="sh">
                                    {{'numeropregunta' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='NumeroPregunta')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('PorcentajeCalificacion')" class="sh">
                                    {{'maximum_calification' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='PorcentajeCalificacion')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('durationtime')" class="sh">
                                    {{'durationtime' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='durationtime')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('myevaluation')" class="sh">
                                    {{'status' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='myevaluation')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('AssigneDateTime')" class="sh">
                                    {{'date' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='AssigneDateTime')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                                <td>{{ i + 1}}</td>
                                <td>{{row.EvaluationName}}</td>
                                <td>{{row.NumeroPregunta}}</td>
                                <td>{{row.PorcentajeCalificacion}}</td>
                                <td>{{row.durationtime}}</td>
                                <!-- <td>{{row.myevaluation == 'RP' ? 'Reprobada' : 'Pendiente'}}</td> -->
                                <td>{{row.myevaluation == 'AP'? 'Aprobada' : row.myevaluation == 'RP'? 'Reprobada' :
                                    'Pendiente'}}</td>
                                <td>{{row.AssigneDateTime | date:'y-MM-dd hh:mm:s'}}</td>
                                <td class="action-buttons">
                                    <i *ngIf="row.typeFile != 'Archivo' && row.myevaluation != 'AP'"
                                        (click)="openModalVideo(viewvideo, row)" style="cursor:pointer;"
                                        class="fas fa-video text-info" title="{{'view_video' | translate}}"></i>
                                    <i *ngIf="row.typeFile == 'Archivo' && row.myevaluation != 'AP'"
                                        (click)="downloadDocFile(row)" style="cursor:pointer;"
                                        class="fas fa-cloud-download-alt text-info m-r-10"
                                        title="{{'download_file' | translate}}"></i>
                                    <i *ngIf="row.typeFile == 'Archivo' && row.myevaluation != 'AP'"
                                        (click)="GoEvaluation(null, row)" style="cursor:pointer;"
                                        class="fas fa-file-alt text-info m-r-10"
                                        title="{{'go_evaluation' | translate}}"></i>
                                    <i *ngIf="row.typeFile == 'Archivo' && row.myevaluation == 'AP'" 
                                        (click)="downloadCertificate(row.EvaluationName)" style="cursor:pointer;"
                                        class="fa fa-certificate text-success m-r-10 "
                                        title="{{'download_certificate' | translate}}"></i>
                                </td>
                            </tr>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize"
                                    [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
            <ng-template #viewvideo let-c="close" let-d="dismiss">
                <div class="modal-header modal-color">
                    <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}:
                        {{u_obj['EvaluationName']}}</h4>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal">
                        <div class="col-12">
                            <div class="row">
                                <iframe width="560" height="315" [src]="urlVideo" frameborder="0"></iframe>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn buttons-pages" style="color: #ffffff;"
                        (click)="closeModal(viewvideo)">{{'cancel' | translate}}</button>
                    <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn buttons-pages"
                        style="color: #ffffff;"
                        (click)="GoEvaluation(viewvideo,u_obj)">{{'go_evaluation'|translate}}</button>
                </div>
            </ng-template>
        </div>
    </div>