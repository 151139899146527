<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('VehicleID')" class="sh">
                                    {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='VehicleID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'v_registration' | translate}}</th>
                                <th>{{'v_brand' | translate}}</th>
                                <th>{{'v_model' | translate}}</th>
                                <th>{{'v_type' | translate}}</th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.VehicleID}}</td>
                                <td>{{row.RegistrationNumber}}</td>
                                <td>{{row.Brand}}</td>
                                <td>{{row.ModelName[0].value}}</td>
                                <td>{{row.VehicleType}}</td>
                                <td class="align-text-top"> <div class="form-check" style="text-align: center;">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.VehicleID}}" (change)="toggleEditable($event,row.VehicleID)" type="checkbox">
                                  </div></td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="goTo('/regVDocu/'+ row.VehicleID, {})" style="cursor:pointer;" class="ti-view-list-alt text-info m-r-10" title="{{'menu_title_man_documento' | translate}}"></i>
                                    <i (click)="updateStatus(row.VehicleID, 3)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <form class="form">
                            <div class="card-body">
                                <div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_registration' | translate}} *</label>
                                            <input type="text" maxlength="8" class="form-control input-top-create"  name="RegistrationNumber" [(ngModel)]="c_obj['RegistrationNumber']" required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_brand' | translate}} *</label>
                                            <select  class="form-control input-top-create" [(ngModel)]="c_obj['MarcaId']" name="MarcaId">
                                                <option *ngFor="let e of brands" [value]="e.ID"> {{e['value']}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_model' | translate}} *</label>
                                            <select  class="form-control input-top-create" [(ngModel)]="c_obj['Model']" name="Model">
                                                <option *ngFor="let e of models" [value]="e.ID"> {{e['value']}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_type' | translate}} *</label>
                                              <select class="form-control input-top-create" [(ngModel)]="c_obj['VehicleTypeId']" name="VehicleTypeId" >
                                                <option *ngFor="let p of type" [value]="p.ID"> {{p['value']}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_color' | translate}} *</label>
                                            <input type="text" class="form-control input-top-create" [(ngModel)]="c_obj['Color']" name="Color" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">
                        <form class="form">
                            <div class="card-body">
                                <div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_registration' | translate}} *</label>
                                            <input type="text" maxlength="8" class="form-control input-top-create"  name="RegistrationNumber" [(ngModel)]="u_obj['RegistrationNumber']" required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_brand' | translate}} *</label>
                                            <select  class="form-control input-top-create" [(ngModel)]="u_obj['MarcaId']" name="MarcaId">
                                                <option *ngFor="let e of brands" [value]="e.ID"> {{e['value']}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_type' | translate}} *</label>
                                              <select class="form-control input-top-create" [(ngModel)]="u_obj['VehicleTypeId']" name="VehicleTypeId" >
                                                <option *ngFor="let p of type" [value]="p.ID"> {{p['value']}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <label for="fname">{{'v_color' | translate}} *</label>
                                            <input type="text" class="form-control input-top-create" [(ngModel)]="u_obj['Color']" name="Color" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
