import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

const API_GET_EMPLOYEES_BY_EVALUATION = environment.Global.API_GET_EMPLOYEES_BY_EVALUATION;
const API_GET_EVALS_BY_TYPE_JOB = environment.Global.API_GET_EVALS_BY_TYPE_JOB;
const API_GET_JOBS_BY_TYPE_EMPLOYEE = environment.Global.API_GET_JOBS_BY_TYPE_EMPLOYEE;

@Component({
  selector: 'app-evalassignbyuser',
  templateUrl: './evalassignbyuser.component.html',
  styleUrls: ['./evalassignbyuser.component.css']
})
export class EvalassignbyuserComponent extends BaseCrudComponent implements OnInit {

  userinfo: any = null;
  typesEmployee = [{ id: 100, name: "Trabajador" }, { id: 101, name: "Conductor" }];
  isVisible = false;
  evaluationsList = [];
  private workbook: Workbook;
  private lista_titulos;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);
    this.search_fields = ['CompanyName', 'TypeCompany',
      'NumberIdentification', 'EmployeeName', 'TypeEmployee', 'EvaluationName', 'StatusEval'];
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
    this.pageSize = 10;
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    await this.getList();
  }

  public async getList() {
    let url = API_GET_EMPLOYEES_BY_EVALUATION;
    this.messageShowLoading();
    await this.srv.getCall(url).toPromise().then(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
        } else {
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      } else {
        // this.messageDialog(x.msg, '','error');
        this.closeLoadingMessage();
        return;
      }
    });
  }


  public validateCreateInfo(fields: any) {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'Name': new FormControl(fields.Name, [Validators.required]),
      'NumberIdentification': new FormControl(fields.NumberIdentification, [Validators.required])
    });

    return mygroup.valid;
  }

  public openCreateModal(content) {
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    this.c_obj = {};
    this.isCreating = false;

  }

  public onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public getEmployeesByType() {
    if (this.c_obj['typeEmployee'] != null && this.c_obj['typeEmployee'] != 101) {
      this.isVisible = true;
      let url = API_GET_JOBS_BY_TYPE_EMPLOYEE + this.c_obj['typeEmployee'];
      this.srv.getCall(url).subscribe(x => {
        if (x.val.length > 0) {
          this.c_obj['jobs'] = x.val;
        }
      });
    } else if (this.c_obj['typeEmployee'] == 101) {
      this.isVisible = false;
      this.c_obj['NumberIdentification'] = null;
      this.c_obj['Name'] = null;
      this.getEvaluationsByTypeJob(1);
    }
  }

  public GetEmployeeforIdentification(obj: any, val: any) {
    console.log(obj);
    if ((obj !== null) && (obj !== undefined) && (obj !== '')) {
      console.log('no vacío')
      var qst = "Employee/GetIdentification/" + obj;
      this.messageShowLoading();
      this.srv.getCall(qst).subscribe(x => {
        if (x.val == null) {
          this.messageDialog('Registro no existe. Verifique si se encuentra previamente registrado', '', 'warning');
        } else
          if (x.val.length > 0) {
            console.log(x.val)
            this.c_obj['CompanyID'] = x.val[0].CompanyID;
            this.c_obj['EmployeeId'] = x.val[0].EmployeeId;
            this.c_obj['Name'] = x.val[0].Name;
            this.c_obj['_JobTypeEmployee'] = x.val[0]._JobTypeEmployee;
            this.c_obj['RequestDetails'] = [
              {
                EmployeeID: x.val[0].EmployeeId,
                JobTypeID: x.val[0].typeEmployeeName == "Conductor" ? 6 : null
              }
            ];
            this.messageDialog(x.msg, '', 'success');
          } else {
            this.messageDialog(x.msg, '', 'error');
          }
      });
    } 
    // else {
    //   console.log('vacío')
    // }
  }

  public getEvaluationsByTypeJob(type?) {
    this.c_obj['ParameEvaID'] = null;
    this.evaluationsList = [];
    if (type == undefined) {
      if (this.c_obj['JobTypeID'] != null && this.c_obj['JobTypeID'] != undefined) {
        let url = API_GET_EVALS_BY_TYPE_JOB + this.c_obj['JobTypeID'];
        this.c_obj['RequestDetails'][0].JobTypeID = this.c_obj['JobTypeID'];
        this.messageShowLoading();
        this.srv.getCall(url).subscribe(x => {
          if (x.success) {
            if (x.val != null) {
              x.val.forEach(element => {
                this.evaluationsList.push(
                  {
                    ParameEvaID: element[0].ParameEvaID,
                    Nombre: element[0].Nombre
                  }
                )
              })
              this.messageDialog(x.msg, '', 'success');
            }
          } else {
            this.messageDialog('Registro no existe. Verifique si se encuentra evaluaciones previamente registradas', '', 'warning');
          }
        })
      }
    } else {
      this.c_obj['JobTypeID'] = 6;
      let url = API_GET_EVALS_BY_TYPE_JOB + this.c_obj['JobTypeID'];
      this.messageShowLoading();
      this.srv.getCall(url).subscribe(x => {
        if (x.success) {
          if (x.val != null) {
            x.val.forEach(element => {
              this.evaluationsList.push(
                {
                  ParameEvaID: element[0].ParameEvaID,
                  Nombre: element[0].Nombre
                }
              )
            })
            this.messageDialog(x.msg, '', 'success');
          }
        }
      })
    }
  }

  public async createItem(content: any, form: any) {
    this.create_url = "Request/Create";
    let qst = "Request/ExistSpecificEval";
    this.isCreating = true;
    var validated = true;
    if (form == undefined) {
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if (!validated) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      this.isCreating = false;
      return;
    }

    if (form.ParameEvaID == undefined || form.ParameEvaID == null) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      this.isCreating = false;
      return;
    }

    if (form.JobTypeID == undefined || form.JobTypeID == null) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      this.isCreating = false;
      return;
    }

    let to = {
      CompanyID: form.CompanyID,
      WhoRequest: this.userinfo['logon'],
      CreatorUserID: this.userinfo['userId'],
      RequestDetails: form.RequestDetails,
      Status: "A",
      ParameEvaID: form.ParameEvaID
    }
    let to2 = {
      ParameEvaID: this.c_obj.ParameEvaID,
      employeeId: this.c_obj.EmployeeId
    };
    let exist = false;
    let msg = "";
    await this.srv.postCall(qst, to2).toPromise().then(x => {
      if (x.success) {
        exist = false;
      } else {
        exist = true;
        msg = x.msg;
      }
    });
    if (exist) {
      this.isCreating = false;
      Swal.fire(msg, '', 'error');
      return;
    }
    console.log(to)
    var url = this.create_url;
    await this.srv.postCall(url, to).toPromise().then(async data => {
      if (data.val != null) {
        this.confirmDialog(data.msg, '', 'success');
        await this.AssigneItem("", data.val["RequestId"]);
      }
    }, catchError => {
      catchError.message;
    });
  }

  public async AssigneItem(content: any, form: any) {
    var qst = "Request/AssigneSpecificEval";
    var to: any;
    to = {
      RequestId: form,
      AssignedUser: "Automatic",
      CompanyID: this.c_obj.CompanyID,
      ParameEvaID: this.c_obj.ParameEvaID,
      employeeId: this.c_obj.EmployeeId
    };
    await this.srv.postCall(qst, to).toPromise().then(async x => {
      if (x.success) {
        await this.SendMail(to, content);
      }
    });
  }

  public async SendMail(data: any, content) {
    var qst = "Request/SenMail";
    var to: any;
    to = {};
    to = data;
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      if (x.success) {
        this.confirmDialogWithModal(x.msg, '', 'success', content);
        this.c_obj = {};
      }
    }, err => {
      this.isCreating = false;
      this.confirmDialogWithModal(err, '', 'error', content);
    });
  }
  downloadExcel() {

    this.workbook = new Workbook();
    let nombreXLSX = "Asignación de cursos virtuales.xlsx";
    this.lista_titulos = [
      

      this.translations.code,
      this.translations.Company,
      this.translations.type_company,
      this.translations.identification,
      this.translations.Employee,
      this.translations.employee_type,
      this.translations.evaluation,
      this.translations.status,
      this.translations.Qualification,

    ]


    this.ContainsExcel();
    this.workbook.creator = "GESCON";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      fs.saveAs(blob, nombreXLSX);
    })
  }
  private ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 8;
    sheet.getColumn("B").width = 20;
    sheet.getColumn("C").width = 20;
    sheet.getColumn("D").width = 20;
    sheet.getColumn("E").width = 20;
    sheet.getColumn("F").width = 20;
    sheet.getColumn("G").width = 20;
    sheet.getColumn("H").width = 20;
    sheet.getColumn("I").width = 20;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });



    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    // console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12 };

    // agregar los datos a las filas
    const rowsToInsert = sheet.getRows(2, this.rows.length);

    for (let index = 0; index < rowsToInsert.length; index++) {
      const itemData = this.rows[index]; //obtenemos el item segun el index de la iteraciòn(recorrido)
      const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
      // los valores de itemData seran asignados a row (fila actual en la iteraciòn)

      row.values = [

        itemData.RequestDetailId,
        itemData.CompanyName,
        itemData.TypeCompany,
        itemData.NumberIdentification,
        itemData.EmployeeName,
        itemData.TypeEmployee,
        itemData.EvaluationName,
        itemData.StatusEval,
        itemData.Score,

      ];

    }
  }

}
