
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div *ngIf="habilitar == true" >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired" ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('EntryRequestId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='EntryRequestId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Requiredby')" class="sh">
                                    {{'requested_by'| translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Requiredby')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'job_order' | translate}}</th>
                                <th>{{'job_to_do' | translate}}</th>
                                <th>{{'date_init' | translate}}</th>
                                <th>{{'date_end' | translate}}</th>
                                <th>{{'emergency' | translate}}</th>
                                <th *ngIf="!isSkretting">{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ;index as i ">
                                <td >{{i + 1}}</td>
                                <td >{{row.Requiredby}}</td>
                                <td >{{row.WorkOrder}}</td>
                                <td >{{row.WorkDescription}}</td>
                                <td>{{row.StartDate.split('T')[0]}}</td>
                                <td>{{row.EndDte.split('T')[0]}}</td>
                                <td>{{row.Emergency ? ('emergency' | translate ): ('no_emergency' | translate)}}</td>
                                <td class="align-text-top" *ngIf="!isSkretting">
                                    <div class="form-check" >
                                        <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.RequestId}}" name="{{row.RequestId}}" (change)="toggleEditable($event,row.EntryRequestId)" type="checkbox">
                                    </div>
                                </td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="dowloadRiskEvaluation(row)" style="cursor:pointer;" class="fas fa-file-alt text-warning m-r-10" title="{{'download_risk_evaluation' | translate}}" *ngIf="!isSkretting"></i>
                                    <i (click)="goToWorkRequest(row)" style="cursor:pointer;" class="far fa-file-alt m-r-10" title="{{'reuest_work' | translate}}" *ngIf="!isSkretting" [hidden]="!row['Risk']"></i>
                                    <i (click)="goToToolList(row)" style="cursor:pointer;" class="far fa-file-alt text-success m-r-10" title="{{'tool_list' | translate}}" *ngIf="!isSkretting"></i>
                                    <i (click)="openUpdateModal(update1, row, 'lg')" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}" *ngIf="!isSkretting"></i>
                                    <i (click)="openUpdateModal(update1, row, 'lg')" style="cursor:pointer;" class="fa fa-eye text-info m-r-10" title="{{'see_detail' | translate}}" *ngIf="isSkretting"></i>
                                    <i (click)="deleteItem(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}" *ngIf="!isSkretting"></i>

                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
                <ng-template #create1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <h4>{{'menu_mtrabajador' | translate}} <label *ngIf="workerList.length== 0">  - {{'loading' | translate}} </label></h4>
                                    <div class="table-responsive">
                                        <table class="table" style="padding: auto;">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'identity_document' | translate}}</th>
                                                    <th>{{'name' | translate}}</th>
                                                    <th>{{'status_documents'| translate}}</th>
                                                    <th>{{'status_reason'| translate}}</th>
                                                    <th>{{'status_evaluations'| translate}}</th>
                                                    <th>{{'status_sanctions'| translate}}</th>
                                                    <th>{{'status' | translate}}</th>
                                                    <!-- <th>{{'colors_scale' | translate}}</th> -->
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let row of workerList  | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2">
                                                    <td>{{row.NumberIdentification}}</td>
                                                    <td style="width:200pt">{{row.Name}}</td>
                                                    <td style="width:100pt">{{row.Status_doc}}</td>
                                                    <td>
                                                      <button type="button" class="btn btn-outline-info" data-toggle="tooltip" data-placement="right" title="{{row.DocReason}}">
                                                        <i class="fa fa-info-circle"></i>
                                                      </button>
                                                      
                                                    </td>
                                                    <td style="width:100pt">{{row.Status_evaluation}}</td>
                                                    <td style="width:100pt">{{row.Status_faltas == 'SI' ? 'SANCIONADO': 'NO SANCIONADO'}}</td>
                                                    <td style="width:100pt">{{row.status_Doc_Eval}}</td>
                                                    <!-- <td>
                                                        <i *ngIf="row.status_Doc_Eval == 'HABILITADO'" style="cursor:pointer; color: green;" class="fa fa-circle"></i>
                                                        <i *ngIf="row.status_Doc_Eval == 'NO HABILITADO'" style="cursor:pointer; color: red;" class="fa fa-circle"></i>
                                                        <i *ngIf="row.status_Doc_Eval == 'SUSPENDIDO'" style="cursor:pointer; color: orange;" class="fa fa-circle"></i>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <td colspan="8">
                                                    <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2" [maxSize]="pageSize2" style="float: right;"></ngb-pagination>
                                                </td>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="col-12">
                            <h4>{{'job_data'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_init_work" class="text-right control-label col-form-label">{{'date_init_work' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_init_work"  [(ngModel)]="c_obj['date_init_work']">
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_final_work" class="text-right control-label col-form-label">{{'date_final_work' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_final_work" id="fechaFinal" [(ngModel)]="c_obj['date_final_work']" (ngModelChange)="verifyDateEnd()">
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_order" class="text-right control-label col-form-label"> {{'job_order' | translate}}</label>
                                    <input type="text"  class="form-control" id="job_order" name="job_order" placeholder="Orden de trabajo..." [(ngModel)]="c_obj['job_order']">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                    <input type="text" class="form-control"  name="name" value="{{userinfo['name']}}" readonly>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_applicant" class="text-right control-label col-form-label"> {{'job_applicant' | translate}} *</label>
                                    <input type="text"  class="form-control" id="job_applicant" name="job_applicant" placeholder="Solicitante..." [(ngModel)]="c_obj['job_applicant']">
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="email_applicant" class="text-right control-label col-form-label"> {{'email_applicant' | translate}} *</label>
                                    <input type="email"  class="form-control" id="email_applicant" name="email_applicant" placeholder="Solicitante..." [(ngModel)]="c_obj['email_applicant']">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}} *</label>
                                    <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="setLevelArea()">
                                        <option [value]="null">{{'Select-level' | translate}}</option>
                                        <option *ngFor="let p of row_plantas" [value]="p.PlantaID + '-' + p.Name">{{p.Name}}</option>
                                    </select> -->
                                    <ng-select [(ngModel)]="c_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="setLevelArea()">
                                        <ng-option *ngFor="let p of row_plantas" [value]="p.PlantaID + '-' + p.Name">{{p.Name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_area" class="text-right control-label col-form-label"> {{'job_area' | translate}} *</label>
                                    <!-- <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['job_area']">
                                        <option *ngFor="let p of row_plantas_area" [value]="p.SeccionID + '-' + p.NameSeccion">{{p.NameSeccion}}</option>
                                    </select>  -->
                                    <ng-select [(ngModel)]="c_obj['job_area']" placeholder="{{'Select_position' | translate}}">
                                        <ng-option *ngFor="let p of row_plantas_area" [value]="p.SeccionID + '-' + p.NameSeccion">{{p.NameSeccion}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="request_area" class="text-right control-label col-form-label"> {{'request_area' | translate}} *</label>
                                    <input type="text"  class="form-control" id="request_area" name="request_area" placeholder="Area..." [(ngModel)]="c_obj['request_area']">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="work_description" class="text-right control-label col-form-label"> {{'job_to_do' | translate}} *</label>
                                    <input type="text"  class="form-control" id="work_description" name="work_description" placeholder="Solicitante..." [(ngModel)]="c_obj['work_description']">
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="risk" class="text-right control-label col-form-label">{{'job_risk' | translate}} *</label>
                                    <input type="checkbox" class="form-control" [(ngModel)]="c_obj['risk']" value="c_obj['risk']" name="risk">
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="emergency" class="text-right control-label col-form-label">{{'emergency' | translate}}</label>
                                    <input type="checkbox" class="form-control" [(ngModel)]="c_obj['emergency']" name="emergency"  (change)="setChecked($event)">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="comment_emergency" class="text-right control-label col-form-label">{{'comment_emergency' | translate}} *</label>
                                    <textarea style="width: 100%;" [(ngModel)]="c_obj['comment_emergency']" placeholder="Indique la razón de emergencia" [disabled]="!c_obj['emergency']"></textarea>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="who_responsable" class="text-right control-label col-form-label">{{'who_responsable' | translate}} *</label>
                                    <textarea style="width: 100%;" [(ngModel)]="c_obj['who_responsable']" placeholder="Debe ingresar al responsable de la persona de emergencia" [disabled]="!c_obj['emergency']"></textarea>
                                </div>
                            </div>
                          <div class="row">
                            <div class="col-md-6 ha-ibox">
                              <label for="WorkActivities" class="text-right control-label col-form-label">{{'work_activities' | translate}} *</label>
                              <textarea style="width: 100%;" [(ngModel)]="c_obj['WorkActivities']" placeholder="Indique el trabajo a realizar"></textarea>
                            </div>
                            <div class="col-md-6 ha-ibox">
                              <label for="ToolsAndKits" class="text-right control-label col-form-label">{{'tools_and_kit' | translate}} *</label>
                              <textarea style="width: 100%;" [(ngModel)]="c_obj['ToolsAndKits']" placeholder="Indique los materiales y equipos a usar"></textarea>
                            </div>
                          </div>
                          <h4>{{'upload_file'| translate}}</h4>
                          <div class="row">
                            <div class="col-md-6 ha-ibox">
                              <div>
                                <label for="fileats" class="text-right control-label col-form-label">{{'ATS' | translate}} *</label>
                                <a [href]="rutaArchivoAts" download="MAIA.xlsx" class="float-right">Descargar Excel</a>
                              </div>

                              <div *ngIf="viewFileInput" style="background-color: lavenderblush">
                                {{ "comp_max_doc_size" | translate }}
                                {{ "max_doc_size2" | translate }} MB
                              </div>
                              <input *ngIf="viewFileInput" type="file" class="form-control" id="fileats" (change)="handleInputChange($event)"/>

                              <div *ngIf="!viewFileInput" class="btn-toolbar mt-2" role="toolbar" aria-label="Toolbar with button groups">
                                <div class="btn-group mr-2" role="group" aria-label="First group">
                                  <button *ngIf="!viewFileInput" type="button" class="btn buttons-pages" [disabled]="u_obj['UrlGeneral'] == ''" (click)="downloadDocFile(u_obj)" style="color: #ffffff;"><i class="fas fa-plus"></i> {{'download_file' | translate}}</button>
                                  <button type="button" class="btn btn-secondary">Aprobar</button>
                                  <button type="button" class="btn btn-secondary">Rechazar</button>
                                </div>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon1"><label class="text-right control-label col-form-label">Aprobado</label></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 ha-ibox">
                              <div>
                                <label for="filemaia" class="text-right control-label col-form-label">{{'MAIA' | translate}} *</label>
                                <a [href]="rutaArchivoMaia" download="MAIA.xlsx" class="float-right">Descargar Excel</a>
                              </div>
                              <div *ngIf="viewFileInput" style="background-color: lavenderblush">
                                {{ "comp_max_doc_size" | translate }}
                                {{ "max_doc_size2" | translate }} MB
                              </div>
                              <input *ngIf="viewFileInput" type="file" class="form-control" id="filemaia" (change)="handleInputChange2($event)"/>

                              <div *ngIf="!viewFileInput" class="btn-toolbar mt-2" role="toolbar" aria-label="Toolbar with button groups">
                                <div class="btn-group mr-2" role="group" aria-label="First group">
                                  <button *ngIf="!viewFileInput" type="button" class="btn buttons-pages" [disabled]="u_obj['UrlGeneral'] == ''" (click)="downloadDocFile(u_obj)" style="color: #ffffff;"><i class="fas fa-plus"></i> {{'download_file' | translate}}</button>
                                  <button type="button" class="btn btn-secondary">Aprobar</button>
                                  <button type="button" class="btn btn-secondary">Rechazar</button>
                                </div>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon2"><label class="text-right control-label col-form-label">Rechazado</label></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            <hr>
                            <h4>{{'data_details_request'| translate}}</h4>
                            <div class="col-md-12 ha-ibox">
                                <h6>**{{'detail_employees'| translate}}</h6>
                            </div>
                            <div class="row">
                                <!-- <div class="col-md-6 ha-ibox">
                                    <label>{{'identification' | translate}} *</label>
                                    <input type="text" maxlength="13" (keypress)="onlyNumberKey($event)" class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)"  >
                                </div> -->
                                <div class="col-md-6 ha-ibox">
                                  <label>{{'identification' | translate}} *</label>
                                  <ng-select [items]="workerFilteredHabilitated"
                                             bindLabel="Name"
                                             bindValue="NumberIdentification"
                                             placeholder="Selecciona un empleado"
                                             [(ngModel)]="c_obj['NumberIdentification']"
                                             (change)="onEmployeeSelect($event)"
                                             searchable="true">
                                  </ng-select>
                                  <!-- <ng-select [(ngModel)]="c_obj['NumberIdentification']" placeholder="{{'worker_name' | translate}}" (change)="setLevelArea()" [disabled]=isSkretting>
                                      <ng-option *ngFor="let t of workerFilteredHabilitated" [value]="t.EmployeeId">{{t.NumberIdentification}} - {{t.Name}}</ng-option>
                                  </ng-select> -->
                                </div>
                                <!--TODO: DEJAR DE USAR LOS SIGUIENTES CAMPOS DE EMPLEADOS, SOLO MANDARLE EL ID DEL EMPLEADO A LA SOLICITUD-->
                                <div class="col-md-6 ha-ibox" style="display: none">
                                    <label>{{'name' | translate}}</label>
                                    <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']"  title="{{'autocomplete'| translate}}" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label>{{'email'|translate}}</label>
                                    <input type="text"  class="form-control" name="Email" [(ngModel)]="c_obj['Email']"  title="{{'autocomplete'| translate}}" readonly>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label>{{'jobtitle' | translate}}</label>
                                    <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}"  readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 ha-ibox"  *ngIf="userinfo.typeCompany != 2">
                                    <label for="job_risk" class="text-right control-label col-form-label">{{'globalparam_typejob' | translate}} *</label>
                                    <ng-select [(ngModel)]="c_obj['jobs']" placeholder="{{'select' | translate}}">
                                        <ng-option *ngFor="let p of row_typejob; let i = index" [value]="p['JobTypeID'] + '-' + p['Name']">{{p['Name']}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3 ha-ibox">
                                    <label>&nbsp;</label>
                                    <br>
                                    <button type="button" class="btn button-modal" (click)="addParameter(c_obj)" style="color: #ffffff;" ngbTooltip="{{'add'|translate}}">
                                        {{'add' | translate}} <i class=" fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <h4>{{'nametype' | translate}}</h4>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'name' | translate}}</th>
                                                    <th>{{'ci' | translate}}</th>
                                                    <th>{{'jobtitle'| translate}}</th>
                                                    <th>{{'globalparam_typejob' | translate}}</th>
                                                    <th>{{'action' | translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let parameter of c_obj['worker']; let i=index">
                                                    <td>{{parameter.NameEmployee}}</td>
                                                    <td>{{parameter.NumberIdentification}}</td>
                                                    <td>{{parameter.jobtitle}}</td>
                                                    <td>{{parameter.globalparam_typejob}}</td>
                                                    <td class="action-buttons">
                                                        <i (click)="delParameter(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>


                <ng-template #update1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" >
                        <div class="row" *ngIf="!isSkretting">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <h4>{{'menu_mtrabajador' | translate}} <label *ngIf="row_emp.length== 0">  - {{'loading' | translate}} </label></h4>
                                    <div class="table-responsive">
                                        <table class="table" style="padding: auto;">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'identification' | translate}}</th>
                                                    <th>{{'name' | translate}}</th>
                                                    <th>{{'status_documents'| translate}}</th>
                                                    <th>{{'status_evaluations'| translate}}</th>
                                                    <th>{{'status' | translate}}</th>
                                                    <!-- <th>{{'colors_scale' | translate}}</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let row of row_emp  | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2">
                                                    <td>{{row.NumberIdentification}}</td>
                                                    <td style="width:200pt">{{row.Name}}</td>
                                                    <td style="width:100pt">{{row.Status_doc}}</td>
                                                    <td style="width:100pt">{{row.Status_evaluation}}</td>
                                                    <td style="width:100pt">{{row.Status_faltas == 'SI' ? 'SANCIONADO': 'NO SANCIONADO'}}</td>
                                                    <td style="width:100pt">{{row.status_Doc_Eval}}</td>
                                                    <!-- <td>
                                                        <i *ngIf="row.status_Doc_Eval == 'HABILITADO'" style="cursor:pointer; color: green;" class="fa fa-circle"></i>
                                                        <i *ngIf="row.status_Doc_Eval == 'NO HABILITADO'" style="cursor:pointer; color: red;" class="fa fa-circle"></i>
                                                        <i *ngIf="row.status_Doc_Eval == 'SUSPENDIDO'" style="cursor:pointer; color: orange;" class="fa fa-circle"></i>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <td colspan="8">
                                                    <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2" [maxSize]="pageSize2" style="float: right;"></ngb-pagination>
                                                </td>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h4>{{'job_data'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_init_work" class="text-right control-label col-form-label">{{'date_init_work' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_init_work"  [(ngModel)]="c_obj['date_init_work']" [disabled]=isSkretting>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_final_work" class="text-right control-label col-form-label">{{'date_final_work' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_final_work" id="fechaFinal" [(ngModel)]="c_obj['date_final_work']" (ngModelChange)="verifyDateEnd()" [disabled]=isSkretting>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_order" class="text-right control-label col-form-label"> {{'job_order' | translate}}</label>
                                    <input type="text"  class="form-control" id="job_order" name="job_order" placeholder="Orden de trabajo..." [(ngModel)]="c_obj['job_order']" [disabled]=isSkretting>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                    <input type="text" class="form-control"  name="name" [(ngModel)]="c_obj['CompanyName']" readonly>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_applicant" class="text-right control-label col-form-label"> {{'job_applicant' | translate}} *</label>
                                    <input type="text"  class="form-control" id="job_applicant" name="job_applicant" placeholder="Solicitante..." [(ngModel)]="c_obj['job_applicant']" [disabled]=isSkretting>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="email_applicant" class="text-right control-label col-form-label"> {{'email_applicant' | translate}} *</label>
                                    <input type="email"  class="form-control" id="email_applicant" name="email_applicant" placeholder="Solicitante..." [(ngModel)]="c_obj['email_applicant']" [disabled]=isSkretting>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}} *</label>
                                    <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="setLevelArea()" [disabled]=isSkretting>
                                        <option *ngFor="let p of row_plantas" [value]="p.PlantaID + '-' + p.Name">{{p.Name}}</option>
                                    </select> -->
                                    <ng-select [(ngModel)]="c_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="setLevelArea()" [disabled]=isSkretting>
                                        <ng-option *ngFor="let p of row_plantas" [value]="p.PlantaID + '-' + p.Name">{{p.Name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_area" class="text-right control-label col-form-label"> {{'job_area' | translate}} *</label>
                                    <!-- <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['job_area']" [disabled]=isSkretting>
                                        <option *ngFor="let p of row_plantas_area" [value]="p.SeccionID + '-' + p.NameSeccion">{{p.NameSeccion}}</option>
                                    </select> -->
                                    <ng-select [(ngModel)]="c_obj['job_area']" placeholder="{{'Select_position' | translate}}"  [disabled]=isSkretting>>
                                        <ng-option *ngFor="let p of row_plantas_area" [value]="p.SeccionID + '-' + p.NameSeccion">{{p.NameSeccion}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="request_area" class="text-right control-label col-form-label"> {{'request_area' | translate}} *</label>
                                    <input type="text"  class="form-control" id="request_area" name="request_area" placeholder="Area..." [(ngModel)]="c_obj['request_area']" [disabled]=isSkretting>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="work_description" class="text-right control-label col-form-label"> {{'job_to_do' | translate}} *</label>
                                    <input type="text"  class="form-control" id="work_description" name="work_description" placeholder="Solicitante..." [(ngModel)]="c_obj['work_description']" [disabled]=isSkretting>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="risk" class="text-right control-label col-form-label">{{'job_risk' | translate}} *</label>
                                    <input type="checkbox" class="form-control" [(ngModel)]="c_obj['risk']" value="c_obj['risk']" name="risk" [disabled]=isSkretting>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="emergency" class="text-right control-label col-form-label">{{'emergency' | translate}}</label>
                                    <input type="checkbox" class="form-control" [(ngModel)]="c_obj['emergency']" value="c_obj['emergency']" name="emergency" [disabled]=isSkretting>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="comment_emergency" class="text-right control-label col-form-label">{{'comment_emergency' | translate}} *</label>
                                    <textarea style="width: 100%;" [(ngModel)]="c_obj['comment_emergency']" placeholder="Indique quien solicita el ingreso" [disabled]="!c_obj['emergency']"></textarea>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="who_responsable" class="text-right control-label col-form-label">{{'who_responsable' | translate}} *</label>
                                    <textarea style="width: 100%;" [(ngModel)]="c_obj['who_responsable']" placeholder="Debe ingresar al responsable de la persona de emergencia" [disabled]="!c_obj['emergency']"></textarea>
                                </div>
                            </div>
                            <hr *ngIf="!isSkretting">
                            <h4 *ngIf="!isSkretting">{{'data_details_request'| translate}}</h4>
                            <div class="col-md-12 ha-ibox" *ngIf="!isSkretting">
                                <h6>**{{'detail_employees'| translate}}</h6>
                            </div>
                            <div class="row" *ngIf="!isSkretting">
                                <div class="col-md-6 ha-ibox">
                                    <label>{{'identification' | translate}} *</label>
                                    <input type="text" maxlength="13" (keypress)="onlyNumberKey($event)" class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)" >
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label>{{'name' | translate}}</label>
                                    <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']"  title="{{'autocomplete'| translate}}" readonly>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isSkretting">
                                <div class="col-md-6 ha-ibox">
                                    <label>{{'email'|translate}}</label>
                                    <input type="text"  class="form-control" name="Email" [(ngModel)]="c_obj['Email']"  title="{{'autocomplete'| translate}}" readonly>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label>{{'jobtitle' | translate}}</label>
                                    <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}"  readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 ha-ibox"  *ngIf="userinfo.typeCompany != 2">
                                    <label for="job_risk" class="text-right control-label col-form-label">{{'globalparam_typejob' | translate}} *</label>
                                    <ng-select [(ngModel)]="c_obj['jobs']" placeholder="{{'select' | translate}}"  [disabled]=isSkretting>>
                                        <ng-option *ngFor="let p of row_typejob; let i = index" [value]="p['JobTypeID'] + '-' + p['Name']">{{p['Name']}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3 ha-ibox">
                                    <label>&nbsp;</label>
                                    <br>
                                    <button type="button" class="btn button-modal" (click)="addParameter(c_obj)" style="color: #ffffff;" ngbTooltip="{{'add'|translate}}">
                                        {{'add' | translate}} <i class=" fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <hr *ngIf="!isSkretting">
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <h4>{{'nametype' | translate}}</h4>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'name' | translate}}</th>
                                                    <th>{{'ci' | translate}}</th>
                                                    <th>{{'jobtitle'| translate}}</th>
                                                    <th>{{'globalparam_typejob' | translate}}</th>
                                                    <th *ngIf="!isSkretting">{{'action' | translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let parameter of c_obj['worker']; let i=index">
                                                    <td>{{parameter.NameEmployee}}</td>
                                                    <td>{{parameter.NumberIdentification}}</td>
                                                    <td>{{parameter.jobtitle}}</td>
                                                    <td>{{parameter.globalparam_typejob}}</td>
                                                    <td class="action-buttons" *ngIf="!isSkretting">
                                                        <i (click)="delParameter(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}" ></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <div *ngIf="!isSkretting">
                            <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, c_obj)">{{'update' | translate}}</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

