// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ConfigVehicle } from "src/app/services/configvehicle";
import { Global } from "src/app/services/global";

export const environment = {
  production: false,
  version: '1.0.0',
  // Key
  KEY: 'GPoyZi048rXxto8KWZVpHozEFBvAXo37',//'234NutrecoCo4562',
  Global,
  ConfigVehicle
};
