import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup,FormBuilder, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { environment } from '../../../../environments/environment';

const KEY = environment.KEY;
const API_REGEMPLOYEE = Global.API_REGISTEREMPLOYEE;
const API_ALLEMPLOYEE = Global.API_ALLEMPLOYEECOMPANYID;
const API_UPDATEEMPLOYEE = Global.API_UPDATEEMPLOYEE;
const API_UPDATESTATUSEMPLOYEE = Global.API_STATUSEMPLOYEE;
const API_CONSDOCUMENEMP = Global.API_CONSPARAMDOCUMENT_VEHICLE;
const API_CONSULTAVEHICLE = Global.API_CONSULTAVEHICLE;
const API_EXIST_EMPLOYEE = environment.Global.API_EXIST_EMPLOYEE;
const API_GET_EMPLOYEE_BY_COMPANY = environment.Global.API_GET_EMPLOYEE_BY_COMPANY;

@Component({
  selector: 'app-regdriver',
  templateUrl: './regdriver.component.html',
  styleUrls: ['./regdriver.component.css']
})
export class RegdriverComponent  extends BaseCrudComponent{
  userinfo:any;
  groupselect:any;
  plantas:any;
  tipotrabajo:any;
  loadingRequired = false;
  translations:any;
  momentjs: any = moment;
  fileName:any;
  foto64;
  imageSrc:any;
  fields:any;
  list_doc:any;
  passuno: string;
  passdos: string;
  passnew: string;
  conversionEncryptOutput: string;  
  conversionDecryptOutput:string;  
  resultado:any;
  form:FormGroup;
  listavehiculos:any;
  default = '../../../assets/images/users/1.jpg';
  


  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    ) { 
      super(srv, modalService, storage, router);
      
      this.read_url = API_ALLEMPLOYEE;
      this.create_url = API_REGEMPLOYEE;
      this.update_url = API_UPDATEEMPLOYEE;
      this.active_url = API_UPDATESTATUSEMPLOYEE;  
      this.list_doc= API_CONSDOCUMENEMP;    
      this.groupselect = [];

      this.search_fields = ['EmployeeId','NumberIdentification','Name','NameSubCompany'];

      this.c_obj['Employee'] = [];

      this.reload.subscribe(val => {
          if( val ){
              super.clearData();
              this.getList();
          }
      });
    }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        this.getList();
    });
  }

  public getList()
  {
    var qst = API_GET_EMPLOYEE_BY_COMPANY + "/" + this.userinfo['selectedCompanyId'];
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        // this.messageDialog(x.msg, '' ,'error');
        this.closeLoadingMessage();
        return;
      }
    });
    this.getCompany();
  }

  public getCompany(){
    let url = 'CompaniesConsulta/SubCompanyByIDCompany';
    var to:any;
    to={};
    to.CompanyID=this.userinfo['selectedCompanyId'];
    this.srv.postCall(url, to).subscribe(x => {
      if (x.success) {
          this.groupselect=x.val;
      } else{
        this.groupselect=[];
        return;
      }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }


  public openCreateModal(content: any) {
    this.default = '../../../assets/images/users/1.jpg';
    this.c_obj = {};
    let curr_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
    this.c_obj['Birth_date'] = curr_date;
    this.modalService.open(content, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }


  onFileChanged(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileName = file.name + ' ' + file.type;
      const str = reader.result.toString();
      this.foto64 = str;
      if (this.u_obj['Picture'] == null) {
        this.u_obj['Picture'] = this.foto64;
      }
      this.imageSrc = str;
      this.default = null;
    };
  }



  openUpdateModal(content:any, obj:any){
    this.isUpdating = false;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.imageSrc = null;
    if (this.u_obj['Picture'] == null) {
     this.default = '../../../assets/images/users/1.jpg';
    }
    this.u_obj['Birth_date'] = obj.Birth_date.split('T')[0];
    this.modalService.open(content, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
    this.foto64=obj.Picture;
  }

  public updateItem(content:any, form:any){
    this.isUpdating = true;
    var qst = this.update_url;
    if( form == undefined ){
      form = {};
    }
    var to:any;

    if( (form.Name == undefined) || (form.Name == '') ){
        Swal.fire(this.translations.msg_all_required, 'msg', 'warning');
        return;
    }
    to = {};
    to = form;
    to.Picture = this.foto64;
    to.LastModifierUserID = this.userinfo['logon'];
    to.CompanyID = this.userinfo['selectedCompanyId'];
    to.Status="A"
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.confirmDialogWithModal(x.msg,'','success', content);
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  toggleEditable(event, id){
    if ( event.target.checked ) {
      this.Activar(id,1);
    }else {
      this.Activar(id,2);
    }
  }

  Activar(obj: any, valor:any){
    var to:any = {};
    to.EmployeeId = obj;
    to.Status=valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      Swal.close();
      if(x.success){
        this.confirmDialog(x.msg,'','success');
      }
      else{
        this.confirmDialog(x.msg,'','error');
      }
    }, err => {
      this.messageDialog(err,'','error');
    });
  }

  searchEmployee() {
    this.srv.getCall(API_EXIST_EMPLOYEE + this.c_obj['NumberIdentification']).subscribe(x => {
      if (x.success ) {
        if (x.val != null) {
          this.messageDialog(x.msg, '' ,'success');
          this.c_obj['NumberIdentification'] = null;
        }
      }
    }, error => {
      this.messageDialog(error, '' ,'error');
    });
  }
  
  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'NumberIdentification': new FormControl(fields.NumberIdentification, [Validators.required]),
        'Name': new FormControl(fields.Name, [Validators.required]),
        'Email': new FormControl(fields.Email, [Validators.required]),
        'telephone': new FormControl(fields.telephone, [Validators.required]),
        'address': new FormControl(fields.address, [Validators.required]),
        'nationality': new FormControl(fields.nationality, [Validators.required]),
        
    });

    return mygroup.valid;
}
  public async createItem(content:any, obj:any){
    var validated = true;
    validated = this.validateCreateInfo(obj);
    if( !validated ){
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }
    obj.Picture = this.foto64;
    obj.CompanyID = this.userinfo.selectedCompanyId;
    obj.CreatorUser = this.userinfo['logon'];
    obj.Status = "A";
    obj.TypeEmployeeID = 101;
    obj.position = "Conductor";
    var qst = this.create_url;
    this.isCreating = true;
    // console.log(obj)
    await this.srv.postCall(qst, obj).toPromise().then(async x => {
      if(x.success){
        await this.CreateUserAndSenmail(x.val, content);
        this.isCreating = false;
      }
      else{
        this.isCreating = false;
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  generapass(numero:any,names:any){
    if (numero!="" && numero!=null) {
      this.passuno = names.substr(0, 3);
      this.passdos = numero.substr(4, 6);
      this.passnew = this.passuno.concat(this.passdos);
      this.conversionEncryptOutput = this.passnew;
    }
  }

  async CreateUserAndSenmail(obj:any, content)
  {
    var qst="Employee/CreateUser";
    this.generapass(obj.NumberIdentification,obj.Name);
    var to = obj;
    to.address=this.conversionEncryptOutput;
    to.position=this.passnew;
    if (this.conversionEncryptOutput=="")
    {
      this.resultado="error";
      return;
    }
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      if (x.success) {
        this.confirmDialogWithModal(this.translations.msg_create, x.msg, 'success', content);
        this.imageSrc = "";
      }
    }, err => {
       Swal.close();
       Swal.fire('Error');
    });
  }


  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  

}

