import { ConfigVehicle } from "src/app/services/configvehicle";
import { Global } from "src/app/services/global";

export const environment = {
  production: true,
  version: '1.0.0',
  KEY: '234NutrecoCo4562',
  Global,
  ConfigVehicle
};
