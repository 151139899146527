import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipemodal'
})
export class PipemodalPipe implements PipeTransform {

  transform(value: boolean): string {
    return  value ? "usert_modal" : "jobt_modal";
  }

}
