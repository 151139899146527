<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn button-modal" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('ParamDocumentID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='ParamDocumentID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('DocumentName')" class="sh">
                                    {{'menu_title_man_docu' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DocumentName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('DocumentDescription')" class="sh">
                                    {{'desc' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DocumentDescription')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('IsByUser')" class="sh">
                                    {{'type' | translate}}<i *ngIf="(orderKey == null)&&(orderKey=='IsByUser')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('DaysAlert')" class="sh">
                                    {{'day_for_alert' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DaysAlert')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Is_required')" class="sh">
                                    {{'Obligatorio' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Is_required')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i ">
                                <td>{{i + 1}}</td>
                                <td>{{row.DocumentName}}</td>
                                <td>{{row.DocumentDescription | uppercase}}</td>
                                <td *ngIf="row.IsByUser == true">{{"usert_modal" | translate}}</td>
                                <td *ngIf="row.IsByUser == false">{{"jobt_modal" | translate}}</td>
                                <td>{{row.DaysAlert}}</td>
                                <td class="align-text-top"><div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Is_required == true) ? 'checked' : null" id="{{row.ParamDocumentID}}" name="{{row.DocumentName}}" type="checkbox" disabled>
                                  </div></td>
                                <td class="align-text-top"> <div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.ParamDocumentID}}" name="{{row.Name}}" (change)="toggleEditable($event,row.ParamDocumentID)" type="checkbox">
                                  </div></td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="DeletedItem(row.ParamDocumentID)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="DocumentName" class="text-right control-label col-form-label">{{'name' | translate}} *</label>
                                <input type="text" class="form-control" [(ngModel)]="c_obj['DocumentName']" name="DocumentName" id="DocumentName" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="desc" class="text-right control-label col-form-label">{{'desc' | translate}}*</label>
                                <textarea class="form-control" [(ngModel)]="c_obj['DocumentDescription']" name="desc" id="DocumentDescription" rows="3"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="alert" class="text-right control-label col-form-label">{{'day_for_alert' | translate}} *</label>
                                <input type="number" class="form-control" [(ngModel)]="c_obj['DaysAlert']" name="day_for_alert" id="day_for_alert" required>
                            </div>
                        </div>

                      <div class="row">
                        <div class="col-md-12 ha-ibox">
                          <label for="type" class="text-right control-label col-form-label">{{'type' | translate}}*</label>
                          <ng-select  name="IsByUser" [(ngModel)]="c_obj['IsByUser']" id="IsByUser" [multiple]="false">
                            <ng-option [value]="true">{{"usert_modal" | translate}}</ng-option>
                            <ng-option [value]="false">{{"jobt_modal" | translate}}</ng-option>

                          </ng-select>
                        </div>
                      </div>
                        <div class="row"  *ngIf="c_obj['IsByUser'] == true">
                            <div class="col-md-12 ha-ibox">
                                <label for="user_type" class="text-right control-label col-form-label">{{'user_type' | translate}}*</label>
                                <ng-select  name="docuTypeID" [(ngModel)]="c_obj['docuTypeID']" id="docuTypeID" [multiple]="true">
                                    <ng-option *ngFor="let listp of listTypeParameter; let i = index" [value]="listp['ID']">{{listp['value']}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="Obligatorio" class="text-right control-label col-form-label">{{'Obligatorio' | translate}}</label> <br>
                                <input class="m-l-10"  name="Obligatorio" id="Obligatorio" (change)="changeupdate($event)" [(ngModel)]="c_obj['Sugerido']" type="checkbox">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="DocumentName" class="text-right control-label col-form-label">{{'name' | translate}} *</label>
                                <input type="text" class="form-control" [(ngModel)]="u_obj['DocumentName']" name="DocumentName" id="DocumentName" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="desc" class="text-right control-label col-form-label">{{'desc' | translate}}*</label>
                                <textarea class="form-control" [(ngModel)]="u_obj['DocumentDescription']" name="desc" id="DocumentDescription" rows="3"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="alert" class="text-right control-label col-form-label">{{'day_for_alert' | translate}} *</label>
                                <input type="number" class="form-control" [(ngModel)]="u_obj['DaysAlert']" name="day_for_alert" id="day_for_alert" required>
                            </div>
                        </div>

                        <div class="row" *ngIf="u_obj['IsByUser'] == true">
                            <div class="col-md-12 ha-ibox">
                                <label for="user_type" class="text-right control-label col-form-label">{{'user_type' | translate}}*</label>
                                <ng-select  name="docuTypeID" [(ngModel)]="u_obj['docuTypeID']" id="docuTypeID" [multiple]="true">
                                    <ng-option *ngFor="let listp of listTypeParameter; let i = index" [value]="listp['ID']">{{listp['value']}}</ng-option>
                                </ng-select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="Obligatorio" class="text-right control-label col-form-label">{{'Obligatorio' | translate}}</label> <br>
                                <input class="m-l-10"  name="Obligatorio" id="Obligatorio" (change)="changeupdate($event)" [(ngModel)]="u_obj['Is_required']" type="checkbox">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button.modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
