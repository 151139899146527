import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { ApiService } from '../../services/main/api.service';
import { Router, Params, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
//import { ApiService } from '../main/api.service';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { PermissionService } from './permission.service';

const API_GENERAL_URL = environment.Global.API_GENERAL_URL;
const API_CONSULTACOMPANIESRUC = Global.API_CONSULTACOMPANIESRUC;

//max time for api requests
const MAX_TIME = 60000;
const TOKEN_KEY = 'token';
const REFRESHTOKEN_KEY = 'refresh';
const PERMISSIONS_KEY = 'permissions';
const MODULES_KEY = 'modules';
const COMPANIES_KEY = 'companies';

const TOKEN_KEY_AQ = 'token_aquasim';
const REFRESHTOKEN_KEY_AQ = 'refreshtoken_aquasim';

const TOKEN_EXPIRE_KEY = 'token_expires_aquasim';
const TOKEN_EXPIRES_PERIOD = 20;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {

  private url: any;
  private select_url:any;
  private token: any;
  public cont: number;
  momentjs: any = moment;
  pages:any;
  modules: any;
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage,
  ) {
    this.url = API_GENERAL_URL;
    this.select_url = API_CONSULTACOMPANIESRUC;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var page = state.url;
    return this.storage.get(PERMISSIONS_KEY).then( async  permissions => {
      let listMenus = ['dashboard', 'settings'];
      let idPage:any;
      let checker: any = [];
      if( !Array.isArray(permissions) ){
        this.clearData().then(() => {
          this._router.navigate(['/authentication/login']);
        });
        return false;
      }
      await permissions.forEach(async permission => {
        // console.log(permission);
        idPage = await this.getPageIdFromModule(permission.Permission);
        if( idPage != false ){
          listMenus.push(idPage);
        }
      });
      await new Promise(resolve => setTimeout(resolve, 250));
      listMenus.forEach( async menu => {
        if (page.toLowerCase().includes(menu.toLowerCase())) {
          await checker.push(true);
        }
      })
      if( checker.length > 0 ){
        // debugger;
        return true;
      }
      else{
        // debugger;
        this.clearData().then(() => {
            this._router.navigate(['/authentication/login']);
        });
        return false;
      };
    });
  }


  /**
   * Function to evaluate modules permissions
   * @param permission permission from modules list
   * @returns true if exist id otherwise false
   */
  async getPageIdFromModule(permission){
    let tmp = [];
    this.pages = await this.getModules().then(data => {
      return data;
    });
    // console.log(this.pages)
    tmp = this.pages.filter((a) => {
      return a.name == permission;
    });
    if( tmp.length > 0 ){
      return tmp[0].id;
    }
    return false;
  }

  /**
   * Function to get data from localstorage
   * @returns Permissions list
   */
  getPermissions(){
    return this.storage.get(PERMISSIONS_KEY);
  }

  /**
   * Function to get data from localstorage
   * @returns Modules list
   */
  getModules() {
    return this.storage.get(MODULES_KEY);
  }
  public login(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}Login/Login`, params, { headers });
  }


  public loginExternal(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}loginExternal/ExternalLogin`, params, { headers });
}

  public getPinCode(email: any) {

    var aq_url='https://wa-t-prevent.azurewebsites.net';
    //var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';
    //debugger;
    const params = {
        //servicetoken: aq_apptoken,
        Ruc: email/*,
        pincode: pin,
        newpassword: pwd*/
    };
    console.log("params:",params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}/api/CompaniesConsulta/CompanySelectRuc`, params, { headers });
    //https://aquasim.skretting.com/
    /*var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';

    const params = {
        servicetoken: aq_apptoken,
        email: email
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/GetPincode`, params, { headers });*/
  }

  public resetPwd(email: any, pin:any, pwd:any) {
    //https://aquasim.skretting.com/
    var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';
    //debugger;
    const params = {
        servicetoken: aq_apptoken,
        email: email,
        pincode: pin,
        newpassword: pwd
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/resetpassword`, params, { headers });
  }

  public saveData(data: any) {
      // console.log(data);

      // data['UserInfo']['result']['ListPermissionsSet'].push({
      //   Crud: 'C|R|U|D', Permission: 'DG_DOCSHOME'
      // })

      // data['UserInfo']['result']['ListPagesSet'].push({
      //   name: 'DG_DOCSHOME', id: 'documentshome', principal: 0
      // })

      console.log(data);

      var promises =
      [
        // this.storage.set('permissions', permissions),
        this.storage.set('userinfo', data['UserInfo']['result']),
        this.storage.set('username', data['UserInfo']['result']['name']),
        this.storage.set('company_id', data['UserInfo']['result']['selectedCompanyId']),
        this.storage.set('permissions', data['UserInfo']['result']['ListPermissionsSet']),
        this.storage.set('modules', data['UserInfo']['result']['ListPagesSet'])
      ];

      return Promise.all(promises);
  }

  public saveAndRefreshInitialData(data: any) {

    this.cont = 0;
    var permissions = [];
    const my_companies: Array<any> = [];
    const temp = Object.keys(data['Permissions']['Result']).map(function(key) {
      return [(data['Permissions']['Result'])[key]];
    });

    if( (data['Permissions'] != undefined) && (data['Permissions']['Result'] != undefined) ){
        if( Array.isArray(data['Permissions']['Result']) ){
            var companies = data['Permissions']['Result'];

            companies.forEach(x => {
                //console.log(x);
                my_companies.push({name: x.cmpName, id: x.cmpID});
                x.Modules.forEach(y => {
                    if( permissions.indexOf(y.Name) < 0 ){
                        permissions.push(y.Name);
                    }
                });
            });
        }
    }

    var exp_time = this.momentjs().add(TOKEN_EXPIRES_PERIOD, 'm').unix();
    // debugger;
    var promises =
    [
      this.storage.set('username', data['UserInfo']['result']['name']),
      this.storage.set('userinfo', data['UserInfo']['result']),
      this.storage.set('token', data['TokenIC2']),
      this.storage.set('refresh', data['RefreshTokenIC2']),
      this.storage.set('token_aquasim', data['TokenAquasym']),
      this.storage.set('refreshtoken_aquasim', data['RefreshTokenAquasym']),
      this.storage.set('token_expires_aquasim', exp_time),
      this.storage.set('permissions', permissions),
      this.storage.set('company_id', data['UserInfo']['result']['selectedCompanyId'])
    ];

    return Promise.all(promises);
  }

  public clearData(){
      return this.storage.clear();
  }

}
