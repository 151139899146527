<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                         <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div>
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                        <button type="button" class="btn buttons-pages" (click)="downloadExcel()" style="color: #ffffff;"><i class="fas fa-plus"></i> {{'download_to_excel' | translate}}</button>
                    </div>
                   
                </div>
                <div class="table-responsive">
                    <div>
                        <table class="table">
                            <thead class="table-head text-white">
                                <tr>
                                    <th>{{'code' | translate}}</th>
                                    <th>{{'Company' | translate}}</th>
                                    <th>{{'type_company' | translate}}</th>
                                    <th>{{'identification' | translate}}</th>
                                    <th>{{'Employee' | translate}}</th>
                                    <th>{{'employee_type' | translate}}</th>
                                    <th>{{'evaluation' | translate}}</th>
                                    <th>{{'status' | translate}}</th>
                                    <th>{{'Qualification' | translate}}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i ">
                                    <!-- <td>{{i + 1}}</td> -->
                                    <td>{{row.RequestDetailId}}</td>
                                    <td>{{row.CompanyName}}</td>
                                    <td>{{row.TypeCompany}}</td>
                                    <td>{{row.NumberIdentification}}</td>
                                    <td>{{row.EmployeeName}}</td>
                                    <td>{{row.TypeEmployee}}</td>
                                    <td>{{row.EvaluationName}}</td>
                                    <td>{{row.StatusEval}}</td>
                                    <td>{{row.Score}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="8">
                                    <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                                </td>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <div class="col-12">
                                    <h4>{{'evaluation_assignation'| translate}}</h4>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <label for="fname">{{'employee_type' | translate}} </label>
                                            <ng-select [(ngModel)]="c_obj['typeEmployee']" placeholder="{{'select' | translate}}" (change)="getEmployeesByType()"  [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let type of typesEmployee" [value]="type['id']">{{type['name']}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <label>{{'identification' | translate}}</label>
                                            <input type="text" maxlength="13" (keypress)="onlyNumberKey($event)"  pattern="[0-9]+" class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)"  >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <label>{{'name' | translate}}</label>
                                            <input type="text"  class="form-control" name="Name" [(ngModel)]="c_obj['Name']"  title="{{'autocomplete'| translate}}" readonly>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox" *ngIf="isVisible">
                                            <label for="fname">{{'globalparam_typejob' | translate}} </label>
                                            <ng-select [(ngModel)]="c_obj['JobTypeID']" placeholder="{{'select' | translate}}" (change)="getEvaluationsByTypeJob()"  [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let job of c_obj['_JobTypeEmployee']" [value]="job['JobTypeID']">{{job['Name']}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <label>{{'select_evaluation' | translate}}</label>
                                            <ng-select [(ngModel)]="c_obj['ParameEvaID']" placeholder="{{'select' | translate}}" [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let evaluation of evaluationsList; index as i" [value]="evaluation['ParameEvaID']">{{evaluation['Nombre']}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

