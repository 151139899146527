import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup,FormBuilder, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { environment } from '../../../../environments/environment';

const KEY = environment.KEY;
const API_REGEMPLOYEE = environment.Global.API_REGISTEREMPLOYEE;
const API_ALLEMPLOYEE = environment.Global.API_ALLEMPLOYEECOMPANYID;
const API_UPDATEEMPLOYEE = environment.Global.API_UPDATEEMPLOYEE;
const API_UPDATESTATUSEMPLOYEE = environment.Global.API_STATUSEMPLOYEE;
// const API_CONSDOCUMENEMP = environment.Global.API_CONSPARAMDOCUMENT_1;
const API_EXIST_EMPLOYEE = environment.Global.API_EXIST_EMPLOYEE;
const API_GET_EMPLOYEE_BY_COMPANY = environment.Global.API_GET_EMPLOYEE_BY_COMPANY;
const API_GET_SYSTEM_PARAMETER_BY_FIELD = environment.Global.API_GET_SEVERITY_TYPE_BY_ID;

@Component({
  selector: 'app-regemployee',
  templateUrl: './regemployee.component.html',
  styleUrls: ['./regemployee.component.css']
})
export class RegemployeeComponent extends BaseCrudComponent{
  userinfo:any;
  groupselect:any;
  plantas:any;
  tipotrabajo:any;
  loadingRequired = false;
  translations:any;
  momentjs: any = moment;
  fileName:any;
  foto64;
  imageSrc:any;
  fields:any;
  list_doc:any;
  curr_parameter:any[];
  curr_parameterl:any[];
  curr_parameter2:any[];
  passuno: string;
  passdos: string;
  passnew: string;
  conversionEncryptOutput: string;
  conversionDecryptOutput:string;
  resultado:any;
  form:FormGroup;
  listavehiculos:any;
  vehiculosSelected = [];
  default = '../../../assets/images/users/1.jpg';
  telephone1 = '';
  telephone2 = '';

  type_document: any[];
  type_nacionality: any[];
  tipo_documento_edit: string;

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private FormBuilder:FormBuilder
    ) {
      super(srv, modalService, storage, router);

      this.read_url = API_ALLEMPLOYEE;
      this.create_url = API_REGEMPLOYEE;
      this.update_url = API_UPDATEEMPLOYEE;
      this.active_url = API_UPDATESTATUSEMPLOYEE;
      // this.list_doc= API_CONSDOCUMENEMP;
      this.groupselect = [];
      this.plantas=[];
      this.tipotrabajo=[];
      this.curr_parameter2 = [];
      this.curr_parameter = [];
      this.curr_parameterl = [];

      this.search_fields = ['EmployeeId','NumberIdentification','Name','NameSubCompany'];

      this.c_obj['Employee'] = [];

      this.reload.subscribe(val => {
          if( val ){
              super.clearData();
              this.getList();
          }
      });
    }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        this.getList();
    });
  }

  public getList()
  {
    var qst = API_GET_EMPLOYEE_BY_COMPANY + "/" + this.userinfo['selectedCompanyId'];
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        // this.messageDialog(x.msg, '' ,'error');
        this.closeLoadingMessage();
        return;
      }
    });
    this.getCompany();
    this.getTypeJob();
    this.getTypeDocument();
    this.getTypeNacionality();
  }

  public getCompany(){
    let url = 'CompaniesConsulta/SubCompanyByIDCompany';
    var to:any;
    to={};
    to.CompanyID=this.userinfo['selectedCompanyId'];
    this.srv.postCall(url, to).subscribe(x => {
      if (x.success) {
          this.groupselect=x.val;
      } else{
        this.groupselect=[];
        return;
      }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }

  public getTypeJob(){
    let url = 'ConsultasGenerales/ConsultaJobTypeActive';

    var to:any;
    to={};
    to.CompanyID=this.userinfo['selectedCompanyId'];
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          this.tipotrabajo = x.val;
        }else {
          this.tipotrabajo = [];
          return;
        }
      }else {
        return;
      }
     });
  }

  public getTypeDocument(){
    let url = API_GET_SYSTEM_PARAMETER_BY_FIELD + 'TYPE_DOCUMENT';

    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          console.log(x.val);
          this.type_document = x.val;
        }
      }
    });
  }

  public getTypeNacionality(){
    let url = API_GET_SYSTEM_PARAMETER_BY_FIELD + 'TYPE_NACIONALITY';

    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          console.log(x.val);
          this.type_nacionality = x.val;
        }
      }
    });
  }

  public openCreateModal(content: any) {
    this.default = '../../../assets/images/users/1.jpg';
    this.c_obj = {};
    let curr_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
    this.c_obj['Birth_date'] = curr_date;
    this.c_obj['EmployeeByJob'] = [];
    this.c_obj['EmployeeByPlanta'] = [];
    this.curr_parameter = [];
    this.curr_parameterl=[];
    this.curr_parameter2=[];
    this.modalService.open(content, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }


  onFileChanged(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileName = file.name + ' ' + file.type;
      const str = reader.result.toString();
      this.foto64 = str;
      if (this.u_obj['Picture'] == null) {
        this.u_obj['Picture'] = this.foto64;
      }
      this.imageSrc = str;
      this.default = null;
    };
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    console.log(fields)
    mygroup = new FormGroup({
        'NumberIdentification': new FormControl(fields.NumberIdentification, [Validators.required]),
        'Name': new FormControl(fields.Name, [Validators.required]),
        'Email': new FormControl(fields.Email, [Validators.required]),
        'telephone': new FormControl(fields.telephone, [Validators.required]),
        'address': new FormControl(fields.address, [Validators.required]),
        'nationalityID': new FormControl(fields.nationalityID, [Validators.required]),
        'position': new FormControl(fields.position, [Validators.required]),
        'TypeIdentificationID': new FormControl(fields.TypeIdentificationID, [Validators.required])
    });

    return mygroup.valid;
}

  openUpdateModal(content:any, obj:any){
    console.log(obj);

    this.tipo_documento_edit = this.type_document.find(type => type.ID === obj.TypeIdentificationID)?.value;
    this.isUpdating = false;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.imageSrc = null;
    if (this.u_obj['Picture'] == null) {
     this.default = '../../../assets/images/users/1.jpg';
    }
    this.u_obj['Birth_date'] = obj.Birth_date.split('T')[0];
    this.curr_parameter= [];
    this.curr_parameterl=[];
    this.curr_parameter2=[];
    this.u_obj._JobTypeEmployee.forEach(element => {
      this.curr_parameter.push(
        element.JobTypeID
      )
    });
    this.modalService.open(content, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
    this.foto64=obj.Picture;

  }

  public updateItem(content:any, form:any){
    this.isUpdating = true;
    var qst = this.update_url;
    if( form == undefined ){
      form = {};
    }
    var to:any;

    if( (form.Name == undefined) || (form.Name == '') ){
        Swal.fire(this.translations.msg_all_required, 'msg', 'warning');
        return;
    }
    to = {};
    to = form;
    to.Picture = this.foto64;
    to.LastModifierUserID = this.userinfo['logon'];
    to.CompanyID = this.userinfo['selectedCompanyId'];
    to.Status="A"
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.curr_parameter = [];
        this.confirmDialogWithModal(x.msg,'','success', content);
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }

   toggleEditable(event, id){
    if ( event.target.checked ) {
      this.Activar(id,1);
    }else {
      this.Activar(id,2);
    }
   }

  Activar(obj: any, valor:any){
    var to:any = {};
    to.EmployeeId = obj;
    to.Status=valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      Swal.close();
      if(x.success){
        this.confirmDialog(x.msg,'','success');
      }
      else{
        this.confirmDialog(x.msg,'','error');
      }
    }, err => {
      this.messageDialog(err,'','error');
    });
  }

  public addJob() {
    this.c_obj.EmployeeByJob = [];
    this.curr_parameter.forEach(element => {
      this.tipotrabajo.forEach(trabajo => {
        if (element == trabajo.JobTypeID) {
          this.c_obj.EmployeeByJob.push({
            JobTypeID: trabajo['JobTypeID'],
            EmployeeId: this.c_obj['EmployeeId'],
            FullName: trabajo['Name']
          })
        }
      });
    });
  }


  public addJobUpdate() {
    this.u_obj.EmployeeByJob = [];
    this.curr_parameter.forEach(element => {
      this.tipotrabajo.forEach(trabajo => {
        if (element == trabajo.JobTypeID) {
          this.u_obj.EmployeeByJob.push({
            JobTypeID: trabajo['JobTypeID'],
            EmployeeId: this.u_obj['EmployeeId'],
            FullName: trabajo['Name']
          })
        }
      });
    });
  }


  searchEmployee() {
    this.srv.getCall(API_EXIST_EMPLOYEE + this.c_obj['NumberIdentification']).subscribe(x => {
      if (x.success ) {
        if (x.val != null) {
          this.messageDialog(x.msg, '' ,'success');
          this.c_obj['NumberIdentification'] = null;
        }
      }
    }, error => {
      this.messageDialog(error, '' ,'error');
    });
  }

  public async createItem(content:any, obj:any){
    console.log(obj);
    const LONG = obj.NumberIdentification.length;
    const TIPO_CEDULA = 208 // ID DEL TIPO DE DOCUMENTO CÉDULA EN SYSTEMPARAMETER
    const ID_IS_NUMBER = !isNaN(obj.NumberIdentification) && obj.NumberIdentification.trim() !== '';
    console.log(ID_IS_NUMBER)
    console.log(TIPO_CEDULA)
    console.log(LONG)
    if(((LONG > 10) || !ID_IS_NUMBER) && (obj.TypeIdentificationID == TIPO_CEDULA)){
      Swal.fire('Está ingresando una cédula inválida', '', 'error');
      return;
    }

    var validated = true;
    validated = this.validateCreateInfo(obj);
    if( !validated ){
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }
    //TODO: eliminar el uso del campo nationality progresivamente, 
    //se hace de esta forma para salir más rápido
    obj.nationality = this.type_nacionality.find(type => type.ID === obj.nationalityID).value;

    obj.Picture = this.foto64;
    obj.CompanyID = this.userinfo.selectedCompanyId;
    obj.CreatorUser = this.userinfo['logon'];
    obj.Status = "A";
    obj.TypeEmployeeID = 100;
    var qst = this.create_url;

    console.log(obj);
    // return;
    this.isCreating = true;
    // validar longitu de cedula
    if ((LONG < 10) && obj.TypeIdentificationID == TIPO_CEDULA) {
      const result = await Swal.fire({
        title: `Está ingresando la cédula\n${obj.NumberIdentification} que contiene ${LONG} dígitos,\n¿desea continuar?`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
      });

      if (result.dismiss === Swal.DismissReason.cancel) {
        this.isCreating = false;
        return;
      }
    }

    console.log(obj)
    await this.srv.postCall(qst, obj).toPromise().then(async x => {
        if(x.success){
          await this.CreateUserAndSenmail(x.val, content);
          this.isCreating = false;
        }
        else{
          this.isCreating = false;
          Swal.fire(x.msg, '', 'error');
        }
    });
  }

  generapass(numero:any,names:any){
    if (numero!="" && numero!=null) {
      this.passuno = names.substr(0, 3);
      this.passdos = numero.substr(4, 6);
      this.passnew = this.passuno.concat(this.passdos);
      this.conversionEncryptOutput = this.passnew;
    }
  }

  async CreateUserAndSenmail(obj:any, content)
  {
    var qst="Employee/CreateUser";
    this.generapass(obj.NumberIdentification,obj.Name);
    var to = obj;
    to.address=this.conversionEncryptOutput;
    to.position=this.passnew;
    if (this.conversionEncryptOutput=="")
    {
      this.resultado="error";
      return;
    }
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      if (x.success) {
        this.confirmDialogWithModal(this.translations.msg_create, x.msg, 'success', content);
        this.imageSrc = "";
      }
    }, err => {
       Swal.close();
       Swal.fire('Error');
    });
  }

  public delParameter(obj:any, position:number)
  {
    obj['EmployeeByJob'].splice(position, 1);
  }
  public delParameter2(obj:any, position:number)
  {
    obj['EmployeeByPlanta'].splice(position, 1);
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


}
