import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSUDOCU = environment.Global.API_CONSPARAMDOCUMENT;
const API_REGISTERDOCU = environment.Global.API_REGPARAMDOCUMEN;
const API_UPDATEDOCU = environment.Global.API_UPDATEPARAMDOCUMEN;
const API_DELETEDOCU = environment.Global.API_UPDATESTATUSPARAMDOCUMEN;
const API_ACTIVEDOCU = environment.Global.API_UPDATESTATUSPARAMDOCUMEN;

@Component({
  selector: 'app-paramdocu',
  templateUrl: './paramdocu.component.html',
  styleUrls: ['./paramdocu.component.css']
})
export class ParamdocuComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  sugeridotemp:any;
  vehiculotemp:any;
  listTypeParameter:any;
  typeModal= true;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSUDOCU;
        this.create_url = API_REGISTERDOCU;
        this.update_url = API_UPDATEDOCU;
        this.delete_url = API_DELETEDOCU;
        this.active_url = API_ACTIVEDOCU;

        this.search_fields = ['DocumentName', 'DocumentDescription'];

        this.c_obj['ParameterDocument'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
            }
        });

   }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.loadingRequired = false;
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.loadingRequired = true;
          this.getList();

    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
    this.getTypeParameterData();
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          this.pageSize = 5;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '','warning');
          this.closeLoadingMessage();
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public createItem(content:any, form:any){
    var validated = true;

    if( form == undefined ){
      form = {};
    }

    validated = this.validateCreateInfo(form);
    if( !validated ){
      var validated = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    var to:any;
    form.CreatorUser = this.userinfo['logon'];
    form.LastModifierUser = this.userinfo['logon'];
    form.sugeridotemp = this.sugeridotemp;
    let typedocument = [];

    form['docuTypeID'].forEach(element => {
      typedocument.push(
        {
          TypeUserByDocumentId: null,
          ParamDocumentID: null,
          ID: element
        }
      )
    })
    form['TypeUserByDocument'] = typedocument;

    to = {
      DocumentName : form.DocumentName,
      DocumentDescription: form.DocumentDescription,
      DaysAlert: form.DaysAlert,
      Is_required: form.Sugerido,
      CreatorUser: form.CreatorUser,
      TypeUserByDocument: form.TypeUserByDocument
    };
    var qst = this.create_url;
    this.isCreating = true;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
        this.getList();
        this.closeModal(content);
        this.confirmDialogWithModal(this.translations.msg_create, x.msg, 'success', content);
      }
      else{
        Swal.fire(x.msg, '', 'error');
      }
    });
  }


  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'DocumentName': new FormControl(fields.DocumentName, [Validators.required]),
        'DocumentDescription': new FormControl(fields.DocumentDescription, []),
        'DaysAlert': new FormControl(fields.DaysAlert, [Validators.required]),
        'docuTypeID': new FormControl(fields.docuTypeID, [Validators.required])
    });

    return mygroup.valid;
  }

  changeupdate(event){
    if ( event.target.checked ) {
     this.sugeridotemp = true;
    }else {
      this.sugeridotemp = false;
    }
  }


  public updateItem(content:any, form:any){
    var qst = this.update_url;
    this.isUpdating = true;

    if( form == undefined ){
      form = {};
    }

    var to:any;

    if( (form.DocumentName == undefined) || (form.DetailName == '') ){
      this.isUpdating = false;
      Swal.fire(this.translations.msg_all_required, '', 'warning');
      return;
    }

    to = {};
    to.DocumentName = form.DocumentName;
    to.DocumentDescription = form.DocumentDescription;
    to.DaysAlert = form.DaysAlert;
    to.TypeUserByDocument = [];
    to.LastModifierUser = this.userinfo['logon'];
    to.ParamDocumentID = form.ParamDocumentID;
    to.Is_required = form.Is_required;
    if (form.docuTypeID.length > 0) {
      form.docuTypeID.forEach(element => {
        let obj = {
          ParamDocumentID: form.ParamDocumentID,
          ID: element
        }
        to.TypeUserByDocument.push(obj)
      })
    }
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        this.confirmDialogWithModal(this.translations.msg_update, x.msg, 'success', content);
      }
    }, err => {
      Swal.close
      Swal.fire('', err, 'error');
    });

  }

  toggleEditable(event, id) {
    if ( event.target.checked ) {
      this.Activar(id, true);
    }else {
      this.Activar(id, false);
    }
  }

  Activar(obj: any, flag){
    var id:any;
    id = {};
    id.ParamDocumentID = obj;
    id.LastModifierUser = this.userinfo['userId'];
    id.Status= flag ? 1: 2;
    id.DeleteUser = this.userinfo['userId'];
    var qst = this.active_url;
    this.srv.postCall(qst, id).subscribe(x => {
      if(x.success){
        this.confirmDialog(this.translations.msg_update, x.msg, 'success');
      }
      else{
        this.confirmDialog('', x.msg, 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
      });
  }

  public getTypeParameterData(){
    var par_url = "SystemParameter/GetSystemParameterField";
    par_url=par_url+"/"+"PARMDOCUMENT";
    this.srv.getCall(par_url).toPromise().then( x => {
      if (x.val.length > 0) {
        this.listTypeParameter = x.val
      }
    });
  }

  public DeletedItem(id:any){
    var to:any;
    to = {};
    to.ParamDocumentID = id;
    to.LastModifierUser =  this.userinfo['userId'];
    to.DeleteUser=this.userinfo['userId'];
    to.Status=3;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.confirmDialog(this.translations.msg_delete, x.msg, 'success');
    }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.c_obj = {};
    this.modalService.open(modal, { centered: true, backdrop: 'static', keyboard: false, });
  }

  public openUpdateModal(modal, obj) {
    this.isUpdating = false;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.u_obj.docuTypeID = [];
    this.modalService.open(modal, {centered: true, backdrop: 'static', keyboard: false, });
    if (this.u_obj.TypeUserByDocument.length > 0) {
      this.u_obj.TypeUserByDocument.forEach(element => {
        this.u_obj.docuTypeID.push(element.ID);
      });
    }
  }


}
