<div class="col">
    <div class="row">
        <div class="col-md-12">
          <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
        </div>
    </div>
    <div class="row m-t-10">
        <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)"
                style="color: #ffffff;"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
        </div>
        <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
            <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages"
                (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' |
                translate}}</button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead class="table-custom-c2621d text-white">
                <tr>
                    <th (click)="setOrder('UserId')" class="sh">
                        ID<i *ngIf="(orderKey!==null)&&(orderKey=='UserId')"
                            [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                    </th>
                    <th (click)="setOrder('username')" class="sh">
                        {{'username'| translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='username')"
                            [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                    </th>
                    <th>{{'name' | translate}}</th>
                    <th>{{'email' | translate}}</th>
                    <th>{{'status' | translate}}</th>
                    <th>{{'habidesa' | translate}}</th>
                    <th>{{'type' | translate}}</th>
                    <th>{{'approve' | translate}}</th>
                    <th>{{'StatusReverse' | translate}}</th>
                    <th>{{'action' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                    <td>{{row.UserId}}</td>
                    <td>{{row.username}}</td>
                    <td>{{row.name}} {{row.lastname}}</td>
                    <td>{{row.email}}</td>
                    <td>{{row.status == 'A' ? 'Activo' : 'Inactivo'}}</td>
                    <td>
                        <div class="form-check">
                            <input class="form-check-input" [attr.checked]="(row.status == 'A') ? 'checked' : null"
                                id="{{row.UserId}}" name="{{row.UserId}}" (change)="activarItem($event,row)"
                                type="checkbox">
                        </div>
                    </td>
                    <td>{{row.tipo}}</td>
                    <td>{{row.DocumentApprove == true ? 'SI' : 'NO'}}</td>
                    <td>{{row.DocumentStatusReverse == true ? 'SI' : 'NO'}}</td>
                    <td class="action-buttons" *ngIf="row.status == 'A'">
                        <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;"
                            class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                        <i (click)="deleteItem(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10"
                            title="{{'delete' | translate}}"></i>

                    </td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="9">
                    <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                        style="float: right;"></ngb-pagination>
                </td>
            </tfoot>
        </table>
    </div>
</div>
<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-custom-c2621d">
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="closeModal(create1)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <h4>{{'internal_user_creation' | translate}}</h4>
                <hr>
            </div>
        </div>
        <div class="text-center">
            <img [src]="imagePath" alt="..." class="rounded-circle image" width="200px" height="200px">
        </div>
        <br>
        <div class="text-center row">
            <div class="col-md-12">
                <input type="file" accept="image/png, image/jpeg" class="custom-file-upload"
                    (change)="getImage($event)">

            </div>
        </div>
        <hr>
        <h4>{{'personal_data' | translate}}</h4>
        <hr>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="identification">{{'identification' | translate}} *</label>
                <input type="text" class="form-control" name="identification" maxlength=10 minlength=10
                    [(ngModel)]="c_obj['identification']" (keypress)="onlyNumberKey($event)">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="birthday">{{'birthday' | translate}} *</label>
                <input type="date" class="form-control" name="birthday" [(ngModel)]="c_obj['birthday']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="name">{{'name' | translate}} *</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="c_obj['name']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="lastname">{{'lastname' | translate}} *</label>
                <input type="text" class="form-control" name="lastname" [(ngModel)]="c_obj['lastname']"
                    [readonly]="c_obj['name']==undefined || c_obj['identification'] == undefined"
                    (blur)="createPassword()">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="mail">{{'email' | translate}} *</label>
                <input type="email" class="form-control" name="mail" [(ngModel)]="c_obj['mail']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="phone">{{'phone' | translate}} *</label>
                <input type="text" class="form-control" name="phone" maxlength=10 minlength=10
                    [(ngModel)]="c_obj['phone']" (keypress)="onlyNumberKey($event)">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <label for="address">{{'address' | translate}} *</label>
                <textarea type="text" class="form-control" name="address" cols="2"
                    [(ngModel)]="c_obj['address']"></textarea>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="sectionid">{{'level' | translate}} *</label>
                <ng-select [(ngModel)]="c_obj['sectionid']" placeholder="{{'Select-level' | translate}}">
                    <ng-option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="areaid">{{'cmp_area' | translate}} *</label>
                <ng-select [(ngModel)]="c_obj['areaid']" placeholder="{{'Select_position' | translate}}">
                    <ng-option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                </ng-select>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="areaid">{{'profile' | translate}} *</label>
                <ng-select [(ngModel)]="c_obj['profile']" placeholder="{{'select_profile' | translate}}">
                    <ng-option *ngFor="let profile of profileList"
                        [value]="profile.ProfileId">{{profile.Name}}</ng-option>
                </ng-select>
            </div>

            <div class="col-md-6 ha-ibox">
                <label for="pass">{{'pass' | translate}} *</label>
                <input type="text" class="form-control" name="pass" [(ngModel)]="password" readonly>
            </div>
        </div>
        <hr>
        <h4>{{'DocumentsActions' | translate}}</h4>
        <hr>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <input type="checkbox" id="DocumentApprove" (change)="ValidaCheck($event,1,create)"
                    [(ngModel)]="c_obj['DocumentApprove']">
                <label style="margin-left: 5px;" for="DocumentApprove">{{'approve' | translate}} / {{'reject' |
                    translate}}</label>
            </div>
            <!-- <div class="col-md-4 ha-ibox">
                 <input type="checkbox" id="ResetPass" (change)="ValidaCheck($event);">
                <input type="checkbox" name="reject" (change)="ValidaCheck($event,2)" [(ngModel)]="c_obj['DocumentReject']">
                <label style="margin-left: 5px;" for="reject">{{'reject' | translate}}</label><br>

            </div> -->
            <div class="col-md-4 ha-ibox">
                <input type="checkbox" name="reverse" (change)="ValidaCheck($event,2,create)"
                    [(ngModel)]="c_obj['DocumentStatusReverse']">
                <label style="margin-left: 5px;" for="reverse">{{'StatusReverse' | translate}}</label><br>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn buttons-pages" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel'
            | translate}}</button>
        <button [ladda]="isCreating" type="button" class="btn buttons-pages" style="color: #ffffff;"
            (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-custom-c2621d">
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="closeModal(create1)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <h4>{{'internal_user_creation' | translate}}</h4>
                <hr>
            </div>
        </div>
        <div class="text-center">
            <img [src]="u_obj['photo'] || imagePath " alt="..." class="rounded-circle image" width="200px"
                height="200px">
        </div>
        <br>
        <div class="text-center row">
            <div class="col-md-12">
                <input type="file" accept="image/png, image/jpeg" class="custom-file-upload"
                    (change)="getImage($event)">
            </div>
        </div>
        <hr>
        <h4>{{'personal_data' | translate}}</h4>
        <hr>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="identification">{{'identification' | translate}} *</label>
                <input type="text" class="form-control" name="identification" maxlength=10 minlength=10
                    [(ngModel)]="u_obj['identification']" (keypress)="onlyNumberKey($event)">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="birthday">{{'birthday' | translate}} *</label>
                <input type="date" class="form-control" name="birthday" [(ngModel)]="u_obj['birthday']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="name">{{'name' | translate}} *</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="u_obj['name']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="lastname">{{'lastname' | translate}} *</label>
                <input type="text" class="form-control" name="lastname" [(ngModel)]="u_obj['lastname']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="mail">{{'email' | translate}} *</label>
                <input type="email" class="form-control" name="mail" [(ngModel)]="u_obj['mail']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="phone">{{'phone' | translate}} *</label>
                <input type="text" class="form-control" name="phone" maxlength=10 minlength=10
                    [(ngModel)]="u_obj['phone']" (keypress)="onlyNumberKey($event)">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <label for="address">{{'address' | translate}} *</label>
                <textarea type="text" class="form-control" name="address" cols="2"
                    [(ngModel)]="u_obj['address']"></textarea>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="sectionid">{{'level' | translate}} *</label>
                <ng-select [(ngModel)]="u_obj['sectionid']" placeholder="{{'Select-level' | translate}}">
                    <ng-option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="areaid">{{'cmp_area' | translate}} *</label>
                <ng-select [(ngModel)]="u_obj['areaid']" placeholder="{{'Select_position' | translate}}">
                    <ng-option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                </ng-select>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="areaid">{{'profile' | translate}} *</label>
                <ng-select [(ngModel)]="u_obj['profile']" placeholder="{{'select_profile' | translate}}">
                    <ng-option *ngFor="let profile of profileList"
                        [value]="profile.ProfileId">{{profile.Name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <hr>
        <h4>{{'user_data' | translate}}</h4>
        <hr>

        <div class="row">
            <div class="col-md-6">
                <label for="user">{{'user' | translate}}</label>
                <input type="text" class="form-control" name="user" [(ngModel)]="u_obj['username']" readonly>
            </div>
            <div class="col-md-6">
                <label for="pass">{{'pass' | translate}}</label>
                <div class="input-container">
                    <input type="text" class="form-control input-field" name="pass" [(ngModel)]="u_obj['password']"
                        [readonly]=editPass>
                    <i class="fas fa-edit icon" (click)="editPassword()"></i>
                </div>
            </div>
        </div>

        <hr>
        <h4>{{'DocumentsActions' | translate}}</h4>
        <hr>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <!-- <input type="checkbox" [checked]="true" [(ngModel)]="u_obj['DocumentApprove']"> -->
                <input name="DocumentApprove" [checked]="u_obj['DocumentApprove']"
                    (change)="ValidaCheck($event,1,update)" [(ngModel)]="u_obj['DocumentApprove']" type="checkbox">
                <label style="margin-left: 5px;" for="DocumentApprove">{{'approve' | translate}} *</label>

            </div>

            <div class="col-md-4 ha-ibox">
                <input type="checkbox" name="DocumentStatusReverse" [checked]="u_obj['DocumentStatusReverse']"
                    [(ngModel)]="u_obj['DocumentStatusReverse']" (change)="ValidaCheck($event,2,update)">
                <label style="margin-left: 5px;" for="DocumentStatusReverse">{{'StatusReverse' | translate}}</label><br>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn buttons-pages" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel'
            | translate}}</button>
        <button [ladda]="isUpdating" type="button" class="btn buttons-pages" style="color: #ffffff;"
            (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
    </div>
</ng-template>
