import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import {saveAs as importedSaveAs  } from "file-saver";
import { environment } from 'src/environments/environment';
import { blobToB64 } from '../approvedocumentsbase/approvedocumentsbase.component';

const API_CONSDOCUMENEMP = environment.Global.API_CONSPARAMDOCUMENT_EMPLOYEE;
const API_GET_FILE_EMPLOYEE_ID = environment.Global.API_GET_FILE_EMPLOYEE_ID;
const API_FILE_EMPLOYEE = environment.Global.API_FILE_EMPLOYEE;
const API_UPDATE_DATA_EMPLOYEE = environment.Global.API_UPDATE_DATA_EMPLOYEE;
const API_GET_DOCUMENT_BY_EMPLOYEE_ID = environment.Global.API_GET_DOCUMENT_BY_EMPLOYEE_ID;
const API_EMPLOYEE_DOCUMENT_UPDATE_STATUS = environment.Global.API_EMPLOYEE_DOCUMENT_UPDATE_STATUS;

@Component({
  selector: 'app-regemployeedoc',
  templateUrl: './regemployeedoc.component.html',
  styleUrls: ['./regemployeedoc.component.css']
})
export class RegemployeedocComponent extends BaseCrudComponent {
  id:any;
  userinfo:any;
  date_doc:any;
  archivos:any;
  namefile:any;
  mensaje="";
  size_max= 3145728;//3mb
  cargavacia=false;
  cargados=false;

  isPDF = true;
  isImage = false;
  pdfSrc = "";
  row2: any = [];

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;
    this.read_url = API_CONSDOCUMENEMP;
    this.date_doc =new Date();
    this.search_fields = ['DocumentName'];
    this.c_obj['EmployeeDocument'] = [];
      
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();          
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.getList();

  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
    var to:any;
    to = {};
    to._IdComp = this.id;
    if (this.userinfo.ProfileID == 2) {
      to._Id = 27; // trabajador
    } else if (this.userinfo.ProfileID == 21) {
      to._Id = 98 // conductor
    }
    this.messageShowLoading();
    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          // this.messageDialog(x.msg, '' , 'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' , 'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.GetDocumentUpload();
  }

  handleInputChange(e) {
    this.archivos = [];
    const fileInput = e.target;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  
    if (!file) {
      this.messageDialog('No se ha seleccionado ningún archivo.', '', 'error');
      return;
    }
  
    const allowedExtensions = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
    const extension = file.type;
  
    if (allowedExtensions.includes(extension)) {
      this.archivos.push(file);
      this.namefile = file.name;
    } else {
      this.messageDialog('Formato de archivo incorrecto!', '', 'error');
      fileInput.value = ''; 
      return;
    }
  }
  async onExpSubmit(row:any) {
    if (this.archivos.length==0)
    {
      Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
      return;
    }
     var size_document=this.archivos[0].size;
    if (size_document > this.size_max){
      Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
      this.archivos=[];
      return;
    } 
    if (row.date_doc == undefined || row.date_doc_end == undefined ) {
      Swal.fire(this.translations.msg_all_required, '' , 'error');
      return;
    }
    let formData = new FormData();
    formData.append('FileUpload',  this.archivos[0]);
    formData.append('EmployeeID', this.id);
    formData.append('ParamDocumentID', row.ParamDocumentID);
    await this.srv.AddFiles(API_FILE_EMPLOYEE, formData ).subscribe(async result => {
      if (result.STATUS) {
        Swal.fire({
          title: result.MENSAJE,
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.uploadDataBase(row);
          }
        })
      }
      else{
        Swal.fire( result.MENSAJE, '', 'error');
        this.archivos=[];
        this.getList();
      }
    });
  }

  public async uploadDataBase(row:any){
    var par_url = API_UPDATE_DATA_EMPLOYEE;
    var to:any = {};
    to.ParamDocumentID=row.ParamDocumentID;
    to.EmployeeId=this.id;
    to.LastModifierUser=this.userinfo.logon;
    to.CreatorUser=this.userinfo.logon;
    to.FileName=this.namefile;
    to.DateDocument=row.date_doc;
    to.ExpirationTime = row.date_doc_end;
    this.loadingList = true;
    await this.srv.postCall(par_url, to).toPromise().then(x => {
      if (x.success) {
        Swal.fire({
          title: x.msg,
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.archivos=[]; 
            this.getList();
            this.loadingList = false;
          }
        })
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public GetDocumentUpload()
  {
    var par_url = API_GET_DOCUMENT_BY_EMPLOYEE_ID + this.id;
    this.srv.getCall(par_url).subscribe(x => {
      if(x.success){
        if(x.val != null && x.val.length > 0){
          var rs = x.val;
          this.rowdocumentupload = rs;
          let flag = false;
          this.rowdocumentupload.forEach(element => {
            if ( element.status_doc == "A" || element.status_doc == "R" ) {
              flag = true;
            }
          });
          if (flag) {
            this.cargados = true;
          } else {
            this.cargados = false;
          }
        }else {
          Swal.fire(x.msg, '', 'success');
          this.rowdocumentupload = [];
          this.cargados=false;
          this.mensaje="";
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  openViewModal(modal, object){
    const DocFileName = object.ParamDocumentID;
    this.row2 = object;
    
    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_EMPLOYEE_ID, this.id, DocFileName)
      .subscribe({
        next: async (blob) => {
          if (blob) {
            var extension = blob.type;
            if (extension == 'application/pdf') {
              this.isPDF = true;
              this.isImage = false;
            } else if (
              extension == 'image/jpeg' ||
              extension == 'image/jpg' ||
              extension == 'image/png'
            ) {
              this.isPDF = false;
              this.isImage = true;
            } else {
              this.messageDialog('Formato de archivo incorrecto!', '', 'error');
              return;
            }
            this.pdfSrc = await blobToB64(blob);
            this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
          } else {
            Swal.fire(
              'No se pudo obtener documento',
              'Contacte con el administrador',
              'error'
            );
          }
        },
        error: (error) => {
          Swal.fire(
            'No se pudo obtener documento',
            'Contacte con el administrador',
            'error'
          );
        }
      });
  }

  downloadDocFile(data) {
    const DocFileName = data.ParamDocumentID;
    const name = data.FileName;
    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_EMPLOYEE_ID, this.id ,DocFileName).subscribe((data) => {
      importedSaveAs(data,name )
    });
  }

  public UpdateStatus()
  {
    var to:any;
    to={};
    to.EmployeeId=this.id;
    to.Status="P";
    to.StatusId=38;
    to.FileName=this.userinfo['name'];
    this.isUpdating = true;
    let trans = this.translations;
    Swal.fire({
        title: trans.sampapp_refmethodswarn,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: trans.cancel,
        confirmButtonText: trans.ok,
        allowOutsideClick: false,
        preConfirm: () => {
          this.saveVerificationAPI(to);
        }
    }).then((result) => {
    });   
  }

  saveVerificationAPI(to:any){
    var par_url = API_EMPLOYEE_DOCUMENT_UPDATE_STATUS;
    this.srv.postCall(par_url, to).subscribe(x => {
      if (x.success) {
        this.confirmDialog(x.msg, '' , 'success');
      }
      else{
        this.confirmDialog(x.msg, '' , 'error');
      }
      this.isUpdating = false;
    });
  }

}
